import React, { useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'antd';
import { MEDIA } from 'utils/constants';
import { useWindowSize } from 'react-use';
import { useNavigate, useLocation } from 'react-router-dom';
// locale
import { useTranslation } from 'react-i18next';
//hooks
import { useQueryFetchWalletHistory } from '../../../../hooks/api';
// store

// types
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
import { EButtonSizeType } from 'types/units';
import {
  ECardColorType,
  ECardSizeType,
} from 'components/features/Cards/EmptyCard/types';
// components
import { FloatRange } from 'components/atoms';
import { HistoryHead } from '../HistoryHead';
import {
  EmptyCard,
  MobileBottomNav,
  TableCellImage,
  TableCellStatus,
  TableCellStickers,
} from 'components/features';

// styles
import './index.scss';

//data
import { formatDate, formatPrice } from '../../../../utils/commons';
import { ArrowLeftLightIcon, ArrowRightIcon } from 'components/icons';
import { EGameType } from 'types/models';
import { TableCellName } from '../../../../components/features/TableCells/TableCellName';

//data

const HistoryPurchase = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const [historyFilter, setHistoryFilter] = useState({
    search: '',
    sort: {},
    page: 1,
    take: 10,
    type: pathname.replace(/^\/history\//, ''),
  });
  const { data: historyData, isLoading: isLoadingHistoryData } =
    useQueryFetchWalletHistory(historyFilter);

  const [tableData, setTableData] = useState<any>();

  useEffect(() => {
    if (!isLoadingHistoryData && historyData) {
      setTableData(transformData(historyData.data));
    }
  }, [isLoadingHistoryData, historyData]);

  const transformData = list => {
    return list.map((el, index) => {
      let { tID, resolvedAt, status, item, total } = el;
      let {
        image,
        preview,
        name,
        float,
        shortenExterior,
        stickers,
        gems,
        provider,
        marketName,
        originalMarketName,
      } = item;
      return {
        id: index,
        dealsId: tID,
        date: formatDate(resolvedAt, 'DD.MM.YYYY HH:mm'),
        status,
        price: total,
        img: image ? image : preview,
        name,
        short: shortenExterior,
        float,
        provider,
        marketName,
        stickers,
        gems,
        originalMarketName,
      };
    });
  };

  const onTableChange = pagination => {
    setHistoryFilter({ ...historyFilter, page: pagination.current });
  };

  const onFilterChange = value => {
    if (value) {
      setHistoryFilter({
        ...historyFilter,
        sort: {
          [value?.key]: value?.data,
        },
      });
    } else {
      let { sort, ...clearedFields } = historyFilter;
      setHistoryFilter({ ...clearedFields, sort: {} });
    }
  };

  const dynamicColumns = useMemo(() => {
    return [
      {
        key: 'date',
        dataIndex: 'date',
        render: date => <div className="ant-table-cell__date">{date}</div>,
      },
      {
        key: 'dealsId',
        dataIndex: 'dealsId',
        render: (_, record) => (
          <div className="ant-table-cell__id">
            ID: <span className="ant-table-cell__value">{record.dealsId}</span>
          </div>
        ),
      },
      {
        key: 'img',
        dataIndex: 'img',
        render: (_, record) => (
          <TableCellImage
            productId={record.id}
            src={record.img}
            gameType={record.provider}
            alt={''}
          />
        ),
      },
      {
        key: 'name',
        dataIndex: 'name',
        render: (_, record) => (
          <TableCellName
            first={record.marketName.first}
            second={record.marketName.second}
            originalMarketName={record.originalMarketName}
            productId={record.id}
            isOverflow={true}
          />
        ),
      },
      {
        key: 'float',
        dataIndex: 'float',
        render: (_, record) => (
          <>
            {record.provider === EGameType.CSGO && (
              <>
                {record.float > 0 && (
                  <div className="ant-table-cell__float">
                    {record.short}
                    <span>{record.float}</span>
                  </div>
                )}
                <FloatRange
                  isTooltip={!record.float}
                  size={EFloatRangeSizeType.Small}
                  maxValue={record.float}
                />
              </>
            )}
          </>
        ),
      },
      {
        key: 'stickers',
        dataIndex: 'stickers',
        width: '50%',
        render: (_, record) => (
          <TableCellStickers
            gameType={record.provider}
            collection={
              record.provider === EGameType.CSGO ? record.stickers : record.gems
            }
          />
        ),
      },
      {
        key: 'price',
        dataIndex: 'price',
        render: price => (
          <span className="ant-table-cell__nowrap ant-table-cell__value">
            $ {formatPrice(price)}
          </span>
        ),
      },
      {
        key: 'status',
        dataIndex: 'status',
        render: status => <TableCellStatus status={status} />,
      },
    ];
  }, []);

  return (
    <>
      <HistoryHead
        filterEnabled={true}
        filterData={historyFilter}
        onChangeFilter={value => {
          onFilterChange(value);
        }}
        onChangeSearch={value => {
          setHistoryFilter({ ...historyFilter, search: value });
        }}
      />
      <div className="history-purchase">
        <div className="app-container">
          <Table
            showHeader={false}
            onChange={pagination => {
              onTableChange(pagination);
            }}
            loading={isLoadingHistoryData}
            pagination={
              isLoadingHistoryData
                ? false
                : {
                    position: ['bottomCenter'],
                    current: historyData?.meta?.page,
                    pageSize: historyData?.meta?.take,
                    total: historyData?.meta?.itemsCount,
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                    itemRender: (page, type, originalElement) => {
                      if (type === 'next') {
                        return <ArrowRightIcon />;
                      } else if (type === 'prev') {
                        return <ArrowLeftLightIcon />;
                      }
                      return originalElement;
                    },
                  }
            }
            className="history-purchase__table"
            columns={dynamicColumns}
            dataSource={tableData}
            rowKey="id"
            scroll={{ x: '980px' }}
            locale={{
              emptyText: (
                <div className="ant-table-cell__table-empty">
                  <EmptyCard
                    size={ECardSizeType.Small}
                    color={ECardColorType.Light}
                    title={t('emptyHistoryTitle')}
                    description={t('emptyHistoryDesc')}
                  />
                  <MobileBottomNav
                    breakPoint={768}
                    className="history-purchase__empty-actions"
                  >
                    <Button
                      className="history-purchase__empty-btn-market"
                      type="primary"
                      block={width <= MEDIA.M768}
                      size={EButtonSizeType.Large}
                      onClick={() => {
                        navigate('/market');
                      }}
                    >
                      {t('goToMarket')}
                    </Button>
                  </MobileBottomNav>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export { HistoryPurchase };
