// core
import React, { useEffect, useState, useMemo } from 'react';
import { Badge, Table } from 'antd';
//locale
import { useTranslation } from 'react-i18next';
// utils
import { formatPrice, formatDate } from '../../../../utils/commons';
// store
import { useAppStore } from 'store';
//hooks
import { useMutationFetchProductTradesDetails } from '../../../../hooks/api';
// types
import { IPropsType } from './types';
import { EBadgeStatusType } from 'types/units';
import { ECardColorType, ECardSizeType } from '../../Cards/EmptyCard/types';

// components
import { EmptyCard, TableCellImage } from 'components/features';
// styles
import './index.scss';

// data
import { ArrowLeftLightIcon, ArrowRightIcon } from 'components/icons';
import { TableCellName } from '../../TableCells/TableCellName';
const transformData = arr => {
  return arr.map((el, index) => {
    const { countBought, countToBuy, createdAt, price, item, id } = el;
    let { image, preview, name, provider, marketName, originalMarketName } =
      item;
    return {
      id,
      date: formatDate(createdAt, 'DD.MM.YYYY'),
      old_price: price,
      count: countBought,
      total: countToBuy,
      name,
      img: image ? image : preview,
      provider,
      marketName,
      originalMarketName,
    };
  });
};
const RequestComparisonModal = ({ modalId, requestData, name }: IPropsType) => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<any>([]);
  const [payload, setPayload] = useState({});

  const {
    data: requestDetails,
    isLoading: isLoadingRequestDetails,
    mutate: mutateRequestDetails,
  } = useMutationFetchProductTradesDetails();

  useEffect(() => {
    if (requestData) {
      setPayload({
        isAverage: requestData.request.isAverage,
        price: requestData.request.price,
        page: 1,
        take: 10,
        nameSearch: name,
      });
      setTableData(transformData(requestData.requestDetails.data));
    }
  }, [requestData, name]);

  useEffect(() => {
    if (!isLoadingRequestDetails && requestDetails) {
      setTableData(transformData(requestDetails?.data));
    }
  }, [requestDetails, isLoadingRequestDetails]);

  const onTableChange = pagination => {
    let newPayload = { ...payload, page: pagination.current };
    mutateRequestDetails(newPayload);
    setPayload(newPayload);
  };

  const columns = [
    {
      key: 'date',
      dataIndex: 'date',
      width: '124px',
    },
    {
      key: 'img',
      dataIndex: 'img',
      render: (_, record) => (
        <TableCellImage
          productId={record.id}
          src={record.img}
          gameType={record.provider}
          alt={''}
        />
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      width: '60%',
      render: (_, record) => (
        <TableCellName
          first={record.marketName.first}
          second={record.marketName.second}
          originalMarketName={record.originalMarketName}
          productId={record.id}
        />
      ),
    },
    {
      key: 'old_price',
      dataIndex: 'old_price',
      width: '120px',
      render: old_price => (
        <div className="ant-table-cell__nowrap ant-table-cell__value">
          $ {formatPrice(old_price)}
        </div>
      ),
    },
    {
      key: 'count',
      dataIndex: 'count',
      render: (_, record) => (
        <div className="ant-table-cell__nowrap">
          {t('autobuyCount')}:{' '}
          <span className="ant-table-cell__value">
            {record.count}/{record.total}
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className="request-comparison-modal">
      <div className="request-comparison-modal__content">
        <div className="request-comparison-modal__head">
          <div className="request-comparison-modal__title">
            {t('productRequest')}{' '}
            {requestData.request.isAverage ? t('more').toLowerCase() : ''} ${' '}
            {formatPrice(Number(requestData.request.price))}
          </div>
          <div className="request-comparison-modal__count">
            <Badge status={EBadgeStatusType.Default} />
            {requestData.request.tradeCount} {t('productRequests')}
          </div>
        </div>
        <div className="request-comparison-modal__body">
          <div className="request-comparison-modal__table">
            <Table
              showHeader={false}
              onChange={pagination => {
                onTableChange(pagination);
              }}
              pagination={
                isLoadingRequestDetails
                  ? false
                  : {
                      position: ['bottomCenter'],
                      current: requestDetails?.meta?.page,
                      pageSize: requestDetails?.meta?.take,
                      total: requestDetails?.meta?.itemsCount,
                      showSizeChanger: false,
                      hideOnSinglePage: true,
                      itemRender: (page, type, originalElement) => {
                        if (type === 'next') {
                          return <ArrowRightIcon />;
                        } else if (type === 'prev') {
                          return <ArrowLeftLightIcon />;
                        }
                        return originalElement;
                      },
                    }
              }
              loading={isLoadingRequestDetails}
              columns={columns}
              dataSource={tableData}
              rowKey="id"
              scroll={{ x: '768px' }}
              locale={{
                emptyText: (
                  <div className="request-comparison-modal__table-empty">
                    <EmptyCard
                      size={ECardSizeType.Small}
                      color={ECardColorType.Light}
                      title={t('emptyRequestTitle')}
                      description={t('emptyRequestDesc')}
                    />
                  </div>
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { RequestComparisonModal };
