import React, { useState, useEffect, useMemo } from 'react';
import { Button, Table } from 'antd';
import { MEDIA } from 'utils/constants';
import { useWindowSize } from 'react-use';
import { useNavigate, useLocation } from 'react-router-dom';
import { formatDate, formatPrice } from '../../../../utils/commons';
// locale
import { useTranslation } from 'react-i18next';
//hooks
import { useQueryFetchWalletHistory } from '../../../../hooks/api';
// store
// types
import { EButtonSizeType } from 'types/units';
import {
  ECardColorType,
  ECardSizeType,
} from 'components/features/Cards/EmptyCard/types';
import { EDealStatusType, EPaymentSystemType } from 'types/models';

// components
import {
  EmptyCard,
  MobileBottomNav,
  TableCellStatus,
} from 'components/features';
import { PaymentVisaIcon, PaymentMasterCardIcon } from 'components/icons';
import { ArrowLeftLightIcon, ArrowRightIcon } from 'components/icons';

// styles
import './index.scss';
import { HistoryHead } from '../HistoryHead';

//data
const getPaymentSystem = paymentSystem => {
  if (paymentSystem === EPaymentSystemType.Visa) {
    return <PaymentVisaIcon />;
  } else if (paymentSystem === EPaymentSystemType.MasterCard) {
    return <PaymentMasterCardIcon />;
  } else if (paymentSystem === EPaymentSystemType.Unknown) {
    return <div className="history-replenishment__unknown-card" />;
  } else return paymentSystem;
  // return null;
};
const HistoryReplenishment = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { width } = useWindowSize();
  const navigate = useNavigate();

  const [historyFilter, setHistoryFilter] = useState({
    page: 1,
    take: 10,
    type: pathname.replace(/^\/history\//, ''),
  });
  const { data: historyData, isLoading: isLoadingHistoryData } =
    useQueryFetchWalletHistory(historyFilter);

  const [tableData, setTableData] = useState<any>();

  useEffect(() => {
    if (!isLoadingHistoryData && historyData) {
      setTableData(transformData(historyData.data));
    }
  }, [isLoadingHistoryData, historyData]);

  const transformData = list => {
    return list.map((el, index) => {
      let { tID, resolvedAt, status, paymentService, amount } = el;

      return {
        id: index,
        paymentId: tID,
        date: formatDate(resolvedAt, 'DD.MM.YYYY HH:mm'),
        status: status,
        price: amount,
        paymentSystem: paymentService,
      };
    });
  };

  const onTableChange = pagination => {
    setHistoryFilter({ ...historyFilter, page: pagination.current });
  };

  const dynamicColumns = useMemo(() => {
    return [
      {
        key: 'date',
        dataIndex: 'date',
        render: date => <div className="ant-table-cell__date">{date}</div>,
      },
      {
        key: 'paymentId',
        dataIndex: 'paymentId',
        width: '35%',
        render: (_, record) => (
          <div className="ant-table-cell__id">
            ID:{' '}
            <span className="ant-table-cell__value">{record.paymentId}</span>
          </div>
        ),
      },
      {
        key: 'paymentSystem',
        dataIndex: 'paymentSystem',
        width: '25%',
        render: (_, record) => (
          <div className="ant-table-cell__nowrap">
            {t('historyPaymentSystem')}:{' '}
            <>{getPaymentSystem(record.paymentSystem)}</>
          </div>
        ),
      },
      {
        key: 'price',
        dataIndex: 'price',
        width: '20%',
        render: price => (
          <span className="ant-table-cell__nowrap ant-table-cell__value">
            $ {formatPrice(price)}
          </span>
        ),
      },
      // {
      //   key: 'paymentGateway',
      //   dataIndex: 'paymentGateway',
      //   render: paymentGateway => (
      //     <div className="ant-table-cell__nowrap">
      //       {t('historyPaymentDirection')}:{' '}
      //       <span className="ant-table-cell__value">{paymentGateway}</span>
      //     </div>
      //   ),
      // },
      {
        key: 'status',
        dataIndex: 'status',
        render: status => <TableCellStatus status={status} />,
      },
    ];
  }, [t]);

  return (
    <>
      <HistoryHead filterEnabled={false} />
      <div className="history-replenishment">
        <div className="app-container">
          <Table
            showHeader={false}
            onChange={pagination => {
              onTableChange(pagination);
            }}
            loading={isLoadingHistoryData}
            pagination={
              isLoadingHistoryData
                ? false
                : {
                    position: ['bottomCenter'],
                    current: historyData?.meta?.page,
                    pageSize: historyData?.meta?.take,
                    total: historyData?.meta?.itemsCount,
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                    itemRender: (page, type, originalElement) => {
                      if (type === 'next') {
                        return <ArrowRightIcon />;
                      } else if (type === 'prev') {
                        return <ArrowLeftLightIcon />;
                      }
                      return originalElement;
                    },
                  }
            }
            className="history-replenishment__table"
            columns={dynamicColumns}
            dataSource={tableData}
            rowKey="id"
            scroll={{ x: '980px' }}
            locale={{
              emptyText: (
                <div className="ant-table-cell__table-empty">
                  <EmptyCard
                    size={ECardSizeType.Small}
                    color={ECardColorType.Light}
                    title={t('emptyHistoryTitle')}
                    description={t('emptyHistoryDesc')}
                  />
                  <MobileBottomNav
                    breakPoint={768}
                    className="history-replenishment__empty-actions"
                  >
                    <Button
                      className="history-replenishment__empty-btn-market"
                      type="primary"
                      block={width <= MEDIA.M768}
                      size={EButtonSizeType.Large}
                      onClick={() => {
                        navigate('/market');
                      }}
                    >
                      {t('goToMarket')}
                    </Button>
                  </MobileBottomNav>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </>
  );
};

export { HistoryReplenishment };
