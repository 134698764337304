import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
import { formatPrice } from '../../../../utils/commons';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useAuthStore, useCartStore } from 'store';

//hooks
import {
  useQueryFetchProductInfo,
  useMutationBuyCart,
  useMutationCheckCart,
} from 'hooks/api';

// types
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
import { EButtonSizeType, EButtonTypeType } from 'types/units';
import { EGameType, IProductType } from 'types/models';
import { ELoaderTypeType } from 'components/atoms/AppLoader/types';
// components
import {
  ArrowRightIcon,
  BargainIcon,
  SteamIcon,
  StickerEmptyIcon,
  CSGOIcon,
} from 'components/icons';

import { FloatRange, AppBtnCart, AppLoader } from 'components/atoms';
import { InfoTooltip, StickerTooltip } from 'components/features';

// styles
import './index.scss';

//data
import { dataTypes } from './data';
/*@ts-ignore*/
import ProductBg from 'assets/img/productBg.png';
import { toast } from 'react-toastify';

const ProductInfo = ({ product }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessToken: isAuth } = useAuthStore();
  const { width } = useWindowSize();
  const { sModalOpen } = useAppStore();
  const { cart, sSetCartData, sSetCartProduct } = useCartStore();
  const [isInCart, setIsInCart] = useState<boolean>(false);

  const {
    data: buyProductData,
    isLoading: isLoadingBuyProduct,
    mutate: mutateBuyProduct,
  } = useMutationBuyCart();

  const {
    data: checkCartData,
    isLoading: isLoadingCheckCart,
    mutate: mutateCheckCart,
  } = useMutationCheckCart();

  useEffect(() => {
    if (!isLoadingCheckCart && checkCartData) {
      if (checkCartData[0].available) {
        mutateBuyProduct([{ sku: checkCartData[0].sku }]);
      } else {
        toast.error(t('toastErrorCheckBuy'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkCartData, isLoadingCheckCart]);

  useEffect(() => {
    if (!isLoadingBuyProduct && buyProductData) {
      toast.success(t('toastItemBought'));
      navigate('/inventory/pending');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyProductData, isLoadingBuyProduct]);

  useEffect(() => {
    if (product) {
      let findData = cart.find(i => i.id === product.id);
      setIsInCart(!!findData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(cart), product]);

  const Stickers = useMemo(() => {
    if (!product) return null;

    let { maxCountSticker, provider, stickers, gems } = product;
    let collection = provider === EGameType.CSGO ? stickers : gems;

    let array: any = [];

    const minimumLength = provider === EGameType.CSGO ? maxCountSticker : 5;
    if (collection?.length === minimumLength) {
      array = collection;
    } else {
      const nullCount = minimumLength - collection.length;
      array = [...collection, ...Array(nullCount).fill(null)];
    }

    return array.map((item, index) => (
      <li key={index}>
        {item ? (
          <>
            <Tooltip
              overlayClassName={'product-info__tooltip ant-tooltip--blur'}
              placement={width > MEDIA.M1024 ? 'right' : 'top'}
              title={
                provider === EGameType.CSGO ? (
                  <StickerTooltip title={item.title} price={item.valueExtra} />
                ) : (
                  <StickerTooltip
                    title={item.title}
                    description={item.valueExtra}
                  />
                )
              }
            >
              <img src={item.preview} alt={item.title} />
            </Tooltip>
          </>
        ) : (
          <>
            <StickerEmptyIcon />
            <div>{t('empty')}</div>
          </>
        )}
      </li>
    ));
  }, [product, t, width]);

  const PriceDiscount = useMemo(() => {
    if (!product) return null;

    let percent = '0.00';
    let { price, marketPrice } = product;

    if (price) {
      percent = ((100 / marketPrice) * price - 100).toFixed(2);
    }

    return (
      <span
        className={
          'product-info__discount-percent' +
          (Number(percent) > 0
            ? ' product-info__discount-percent--overpayment'
            : '')
        }
      >
        {Number(percent) > 300 ? '>300' : percent}%
      </span>
    );
  }, [product]);

  const onBuy = () => {
    if (isAuth) {
      mutateCheckCart([{ sku: product.sku }]);
    } else {
      sModalOpen('steamAuth');
    }
  };

  const onBargain = () => {
    if (isAuth) {
      sSetCartProduct(product);
      sModalOpen('offerTrade');
    } else {
      sModalOpen('steamAuth');
    }
  };

  const onCartAdd = () => {
    sSetCartData([...cart, product]);
  };

  const onCartRemove = productId => {
    let newCartData = cart.filter(item => item.id !== productId);
    sSetCartData(newCartData);
  };

  const onViewInGame = url => {
    window.open(url, '_top');
  };

  return (
    <div className="product-info" id="info">
      <div className="product-info__head">
        <div className="product-info__title">
          {product.provider === EGameType.CSGO
            ? product.marketName.second
              ? product.marketName.first + ' | ' + product.marketName.second
              : product.marketName.first
            : product.marketName.first}
        </div>
        {product.provider === EGameType.CSGO && (
          <div className="product-info__actions ant-radio-group ant-radio-group-solid ant-radio-group-middle">
            {product.wears?.map((item, index) => {
              return (
                <RouterLink
                  to={`/market/${item.itemId}`}
                  className={`ant-radio-button-wrapper ${
                    !item.itemId && ' ant-radio-button-wrapper-disabled'
                  } ${
                    item.name === product.exterior &&
                    ' ant-radio-button-wrapper-checked'
                  }`}
                  key={index}
                >
                  <span>{item.name}</span>
                </RouterLink>
              );
            })}
          </div>
        )}
      </div>
      <div className="product-info__grid">
        <div className="product-info__aside">
          <div className="product-info__card product-info__card--steam">
            ${' '}
            {product.isInMarket ? (
              <>
                {formatPrice(product.price)}
                <div className="product-card-csgo__head-sale">
                  <SteamIcon />
                  {PriceDiscount}
                </div>
              </>
            ) : (
              <>{formatPrice(product.marketPrice)}</>
            )}
          </div>
          {product.provider === EGameType.CSGO && product.float > 0 && (
            <div className="product-info__card product-info__card--float">
              <div className="product-info__card-float-grid">
                {t('floatValue')}
                <span>{product.float}</span>
              </div>
              <FloatRange
                size={EFloatRangeSizeType.Default}
                isTooltip={true}
                maxValue={product.float}
              />
            </div>
          )}
          <div className="product-info__card product-info__card--rarity">
            {t('rarity')}
            <span style={{ color: `#${product.tag.rarity.valueExtra}` }}>
              {product.tag.rarity.title}
            </span>
          </div>

          {product.provider === EGameType.CSGO &&
          (product?.collections?.length || product?.crates?.length) ? (
            <div className="product-info__card product-info__card--collection">
              {t('collection')}
              <ul className="product-info__collection">
                {product?.collections?.map((el, index) => {
                  return (
                    <li key={`collection${index}`}>
                      <img src={el.image} alt={el.name} />
                      <span>{el.name}</span>
                    </li>
                  );
                })}
                {product?.crates?.map((el, index) => {
                  return (
                    <li key={`cart${index}`}>
                      <img src={el.image} alt={el.name} />
                      <span>{el.name}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <></>
          )}
          {product.isInMarket && (
            <div className="product-info__aside-actions">
              <Button
                block
                disabled={isLoadingBuyProduct || product.isOwn}
                size={EButtonSizeType.Large}
                type={EButtonTypeType.Primary}
                onClick={() => onBuy()}
              >
                {product.isOwn ? t('yourItem') : t('buyItem')}
              </Button>

              <Tooltip
                overlayClassName={'ant-tooltip--small'}
                placement="top"
                title={<InfoTooltip text={t('tooltipBargainBtn')} />}
              >
                <Button
                  disabled={product.isOwn}
                  size={EButtonSizeType.Large}
                  ghost
                  type={EButtonTypeType.Primary}
                  onClick={() => onBargain()}
                  icon={<BargainIcon />}
                />
              </Tooltip>

              <AppBtnCart
                disabled={product.isOwn}
                size={EButtonSizeType.Large}
                isTooltip={true}
                isInCart={isInCart}
                onClickAdd={() => onCartAdd()}
                onClickRemove={() => onCartRemove(product.id)}
              />
            </div>
          )}
        </div>
        <div className="product-info__main">
          <ul
            className={
              'product-info__stickers' +
              (product.provider === EGameType.CSGO
                ? ' product-info__stickers--csgo'
                : ' product-info__stickers--dota')
            }
          >
            {product.provider === EGameType.CSGO ? (
              <>{product.maxCountSticker > 0 && Stickers}</>
            ) : (
              Stickers
            )}
          </ul>
          <div className="product-info__banner">
            <div
              className="product-info__gradient"
              style={{
                backgroundImage:
                  width > MEDIA.M1360
                    ? `radial-gradient(circle at 50% 60%, #${product.tag.rarity.valueExtra}CC 10%, #131215 65%), url(${ProductBg})`
                    : `radial-gradient(ellipse at 50% 50%, #${product.tag.rarity.valueExtra}CC 20%, #131215 75%), url(${ProductBg})`,
              }}
            ></div>
            <div className="product-info__banner-img">
              <img
                src={product.image ? product.image : product.preview}
                alt={product.name}
              />
            </div>
            {width > MEDIA.M1024 &&
              product.provider === EGameType.CSGO &&
              product.isInMarket && (
                <div className="product-info__banner-actions">
                  <div className="product-info__banner-game">
                    {t('openGameAfterPress')}
                    <CSGOIcon />
                  </div>
                  <Button
                    ghost
                    disabled={!product.actions.length}
                    type={EButtonTypeType.Primary}
                    onClick={() => {
                      onViewInGame(product.actions[0].link);
                    }}
                  >
                    {t('checkInGame')}
                    <ArrowRightIcon />
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProductInfo };
