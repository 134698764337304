// core
import React, { useMemo } from 'react';
import { Button } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from 'utils/constants';
// utils
import { formatPrice } from '../../../../../utils/commons';
// locales

// store

// types
import { IPropsType } from './types';
import { EGameType } from 'types/models';
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';

// components
import { TrashIcon } from 'components/icons';
import { FloatRange } from 'components/atoms';
// styles
import './index.scss';
import { formatFloat } from '../../../../../utils/commons';
import { StickersBox } from 'components/features';

const CartItem = ({ data, onDelete }: IPropsType) => {
  const gameType = data?.provider;
  const { width } = useWindowSize();

  const Stickers = useMemo(() => {
    let collection = gameType === EGameType.CSGO ? data.stickers : data.gems;
    if (!collection.length) return null;
    return (
      <StickersBox
        collection={collection}
        sku={data.sku}
        gameType={gameType}
        containerClassName={'cart-item__stickers'}
      />
    );
  }, [data, gameType]);

  return (
    <div className="cart-item">
      <div className="cart-item__product">
        <RouterLink to={`/market/${data.id}`} className="cart-item__img">
          <img
            src={data.image?.length ? data.image : data.preview}
            alt={data.name}
          />
        </RouterLink>
        <div className="cart-item__info">
          <div className="cart-item__title">{data.marketName.first}</div>
          {gameType === EGameType.CSGO ? (
            <>
              <div className="cart-item__label">{data.marketName.second}</div>
              {width <= MEDIA.M1024 && <>{Stickers}</>}
              {data.float > 0 && (
                <div className="cart-item__float">
                  <div className="cart-item__float-grid">
                    <span>{data.shortenExterior}</span>
                    <span>{formatFloat(data.float)}</span>
                  </div>
                  <FloatRange
                    isTooltip={!!data.float}
                    size={EFloatRangeSizeType.Small}
                    maxValue={data.float ? formatFloat(data.float) : 0}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="cart-item__label">{data.slot}</div>
            </>
          )}
        </div>
        {width > MEDIA.M1024 && <>{Stickers}</>}
      </div>
      <div className="cart-item__price">$ {formatPrice(data.price)}</div>
      <Button
        ghost
        className="ant-btn-ghost--error cart-item__btn-delete"
        icon={<TrashIcon />}
        onClick={() => onDelete(data)}
      />
    </div>
  );
};
export { CartItem };
