import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// utils

// locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';
//hooks
import {
  useQueryFetchProfileInfo,
  useQueryFetchSteamSettings,
  useMutationSendSteamSettings,
} from '../../../../hooks/api';

// types
import { EButtonSizeType, EAvatarSizeType } from 'types/units';
import { ELoaderTypeType } from '../../../../components/atoms/AppLoader/types';

// components(atoms)
import { Avatar, Button, Input, Space, Typography, Form } from 'antd';
import { DashboardBlock } from '../DashboardBlock';
import { AppLoader, ModalTeleport } from 'components/atoms';
import {
  LinkIcon,
  KeyBorderedIcon,
  SteamIcon,
  CopyIcon,
  EyeIcon,
} from 'components/icons';
import { MobileBottomNav } from 'components/features';
import { DashboardHead } from '../DashboardHead';

// styles
import './index.scss';

// data
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { DashboardModalEnterPassword } from '../DashboardModal/DashboardModalEnterPassword';

// func
const DashboardSteam = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Paragraph } = Typography;
  const { sModalOpen } = useAppStore();
  const [visibleApiKey, setVisibleApiKey] = useState<boolean>(false);
  const [formTrade] = Form.useForm();
  const [formApi] = Form.useForm();
  const createSteamTradeSchema = yup.object().shape({
    tradeOfferLink: yup.string(),
  });
  const createSteamApiSchema = yup.object().shape({
    webApi: yup
      .string()
      .nullable()
      .matches(/^[0-9A-Fa-f]{32}$/, t('validationSteamApiKey')),
  });

  const yupSyncTrade = {
    async validator({ field }, value) {
      await createSteamTradeSchema.validateSyncAt(field, { [field]: value });
    },
  };
  const yupSyncApi = {
    async validator({ field }, value) {
      await createSteamApiSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const [disabledTradeLink, setDisabledTradeLink] = useState(true);
  const [disabledSteamKey, setDisabledSteamKey] = useState(true);

  const inputRefTrade = useRef<any>(null);
  const inputRefApi = useRef<any>(null);

  useEffect(() => {
    if (!disabledTradeLink) {
      inputRefTrade!.current!.focus();
    }
  }, [disabledTradeLink]);

  useEffect(() => {
    if (!disabledSteamKey) {
      inputRefApi!.current!.focus();
    }
  }, [disabledSteamKey]);

  const { data: profileData, isLoading: isLoadingProfileData } =
    useQueryFetchProfileInfo(false);

  const {
    data: steamData,
    isLoading: isLoadingSteamData,
    refetch: refetchSteamSettings,
  } = useQueryFetchSteamSettings();

  useEffect(() => {
    if (steamData) {
      formTrade.setFieldsValue({
        tradeOfferLink: steamData.tradeOfferLink,
      });
      formApi.setFieldsValue({
        webApi: steamData.webApi,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [steamData]);

  const {
    data: sendSteamData,
    isLoading: isLoadingSendSteam,
    mutate: mutateSendSteamSettings,
  } = useMutationSendSteamSettings();

  useEffect(() => {
    if (!isLoadingSteamData && sendSteamData) {
      setDisabledSteamKey(true);
      setDisabledTradeLink(true);
      toast.success(t('toastSteamSettings'));
      refetchSteamSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendSteamData, isLoadingSendSteam]);

  const saveTrade = () => {
    formTrade.submit();
  };
  const saveApi = () => {
    if (profileData?.paymentPassword) {
      sModalOpen('verifyPaymentPasswordToSaveApi');
    } else {
      toast.error(t('toastErrorCreatePaymentPass'));
      navigate('/dashboard/security-and-payments?createPassword=true');
    }
  };
  const onSubmitTradeForm = payload => {
    mutateSendSteamSettings(payload);
    setDisabledTradeLink(!disabledTradeLink);
  };
  const onSubmitApiForm = payload => {
    mutateSendSteamSettings(payload);
    setDisabledSteamKey(!disabledSteamKey);
    setVisibleApiKey(false);
  };
  const onGetTradeLink = () => {
    window.open(
      'https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url',
      '_blank',
    );
  };
  const onGetSteamApiKey = () => {
    window.open('https://steamcommunity.com/dev/apikey', '_blank');
  };

  const onSubmitPassword = () => {
    setVisibleApiKey(true);
  };
  const onSubmitPasswordApi = () => {
    formApi.submit();
  };

  const onKeyVision = () => {
    if (visibleApiKey) {
      setVisibleApiKey(!visibleApiKey);
    } else {
      if (profileData?.paymentPassword) {
        sModalOpen('verifyPaymentPassword');
      } else {
        toast.error(t('toastErrorCreatePaymentPass'));
        navigate('/dashboard/security-and-payments?createPassword=true');
      }
    }
  };

  return (
    <div className="dashboard__main">
      <div className="dashboard__main--wrap">
        {isLoadingProfileData && isLoadingSteamData ? (
          <div className="dashboard__main-loader">
            <AppLoader type={ELoaderTypeType.Component} />
          </div>
        ) : (
          <>
            {profileData && steamData && (
              <>
                <DashboardHead title={t('steamAccount')}>
                  <></>
                </DashboardHead>
                <div className="dashboard__main--user">
                  <div className="dashboard__main--user-data">
                    <div className="dashboard__main--user-data-avatar">
                      <div className="dashboard__main--user-data-steam">
                        <SteamIcon />
                      </div>
                      <h3>Steam</h3>
                    </div>
                  </div>
                  <div className="dashboard__main--user-id dashboard__main--user-id--wide">
                    <Paragraph
                      copyable={{
                        icon: [
                          <CopyIcon key="copy-icon" />,
                          <CopyIcon key="copied-icon" />,
                        ],
                        text: profileData?.uuid,
                      }}
                    >
                      STEAM ID: {profileData?.uuid}
                    </Paragraph>
                  </div>
                </div>
                <DashboardBlock
                  title={t('steamSettingsTitle')}
                  description={t('steamSettingsDesc')}
                >
                  <div className="dashboard__main-form">
                    <Form
                      onFinish={onSubmitTradeForm}
                      form={formTrade}
                      layout="vertical"
                    >
                      <div className="dashboard__main-form-input dashboard__main-form-input--steam">
                        <Form.Item
                          name={'tradeOfferLink'}
                          /*@ts-ignore*/
                          rules={[yupSyncTrade]}
                        >
                          <Input
                            className="ant-input-group-wrapper--custom-addon"
                            disabled={disabledTradeLink}
                            ref={inputRefTrade}
                            addonBefore={
                              <>
                                <LinkIcon />
                                <span>{t('tradeLink')}</span>
                              </>
                            }
                            addonAfter={
                              <>
                                {disabledTradeLink ? (
                                  <Button
                                    size={EButtonSizeType.Middle}
                                    className="ant-btn-text--primary"
                                    type="text"
                                    onClick={() => {
                                      setDisabledTradeLink(!disabledTradeLink);
                                    }}
                                  >
                                    {t('change')}
                                  </Button>
                                ) : (
                                  <Button
                                    size={EButtonSizeType.Middle}
                                    className="ant-btn-text--primary"
                                    type="text"
                                    onClick={() => {
                                      saveTrade();
                                    }}
                                  >
                                    {t('save')}
                                  </Button>
                                )}
                                <Button
                                  size={EButtonSizeType.Middle}
                                  className="ant-btn-text--primary"
                                  type="text"
                                  onClick={() => {
                                    onGetTradeLink();
                                  }}
                                >
                                  {t('get')}
                                </Button>
                              </>
                            }
                          />
                        </Form.Item>
                      </div>
                    </Form>
                    <Form
                      onFinish={onSubmitApiForm}
                      form={formApi}
                      layout="vertical"
                    >
                      <div className="dashboard__main-form-input dashboard__main-form-input--steam">
                        <Form.Item
                          name={'webApi'}
                          /*@ts-ignore*/
                          rules={[yupSyncApi]}
                        >
                          <Input
                            className="ant-input-group-wrapper--custom-addon"
                            disabled={disabledSteamKey}
                            ref={inputRefApi}
                            type={visibleApiKey ? 'text' : 'password'}
                            addonBefore={
                              <>
                                <KeyBorderedIcon />
                                <span>{t('steamApiKey')}</span>
                              </>
                            }
                            addonAfter={
                              <>
                                <Button
                                  size={EButtonSizeType.Middle}
                                  className="ant-btn-text--primary"
                                  type="text"
                                  onClick={() => {
                                    onKeyVision();
                                  }}
                                  icon={<EyeIcon />}
                                ></Button>
                                {disabledSteamKey ? (
                                  <Button
                                    size={EButtonSizeType.Middle}
                                    className="ant-btn-text--primary"
                                    type="text"
                                    onClick={() => {
                                      setDisabledSteamKey(!disabledSteamKey);
                                    }}
                                  >
                                    {t('change')}
                                  </Button>
                                ) : (
                                  <Button
                                    size={EButtonSizeType.Middle}
                                    className="ant-btn-text--primary"
                                    type="text"
                                    onClick={() => {
                                      saveApi();
                                    }}
                                  >
                                    {t('save')}
                                  </Button>
                                )}
                                <Button
                                  size={EButtonSizeType.Middle}
                                  className="ant-btn-text--primary"
                                  type="text"
                                  onClick={() => {
                                    onGetSteamApiKey();
                                  }}
                                >
                                  {t('get')}
                                </Button>
                              </>
                            }
                          />
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </DashboardBlock>
                <ModalTeleport
                  centered
                  modalId={'verifyPaymentPassword'}
                  width={500}
                  modalClass={''}
                >
                  <DashboardModalEnterPassword
                    modalId={'verifyPaymentPassword'}
                    onSubmitPassword={onSubmitPassword}
                  />
                </ModalTeleport>
                <ModalTeleport
                  centered
                  modalId={'verifyPaymentPasswordToSaveApi'}
                  width={500}
                  modalClass={''}
                >
                  <DashboardModalEnterPassword
                    modalId={'verifyPaymentPasswordToSaveApi'}
                    onSubmitPassword={onSubmitPasswordApi}
                  />
                </ModalTeleport>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export { DashboardSteam };
