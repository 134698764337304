// core
import React, { useEffect, useState } from 'react';
import { Button, Radio, RadioChangeEvent } from 'antd';
import { Area } from '@ant-design/plots';
import dayjs from 'dayjs';

// utils
import { formatPrice } from '../../../../utils/commons';
//locales
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';
//hooks
import {
  useQueryFetchPriceHistory,
  useMutationCreateAutobuy,
} from '../../../../hooks/api';
import { useQueryFetchSearchAllProducts } from '../../../../hooks/api';
// types
import { ENewTradeViewModalType, IPropsType } from './types';
import {
  ESelectSizeType,
  EButtonSizeType,
  EButtonTypeType,
  ERadioButtonStyleType,
  ERadioButtonSizeType,
} from 'types/units';
import { EGameType } from 'types/models';

// components
import { AppAutocomplete } from 'components/atoms/AppAutocomplete';
import { EmptyCard, NewTradeModalCard } from 'components/features';
// styles
import './index.scss';

// data
import { generalChartConfig, generalTooltipConfig } from 'config/charts';
import { toast } from 'react-toastify';
import { ECardSizeType } from '../../Cards/EmptyCard/types';
import { dateTypes } from './data';

const NewTradeModal = ({ data, modalId, onTradeCreated }: IPropsType) => {
  const { t } = useTranslation();
  const { sModalClose } = useAppStore();

  const [modalType, setModalType] = useState<ENewTradeViewModalType>(
    ENewTradeViewModalType.Autocomplete,
  );

  const [sortQuality, setSortQuality] = useState<any>({
    title: 'Factory New',
    value: 'WearCategory0',
  });
  const [options, setOptions] = useState<any[]>([]);
  const [searchPayload, setSearchPayload] = useState<any>({ nameSearch: '' });

  const [product, setProduct] = useState<any>(null);
  const [chartData, setChartData] = useState([]);
  const [summary, setSummary] = useState<number>(0);
  const [enabledFetchChart, setEnabledFetchChart] = useState(false);

  const [chartDateType, setChartDateType] = useState(dateTypes[0].range);

  const [localData, setLocalData] = useState<any>(null);
  const [filterData, setFilterData] = useState<any>(null);
  const {
    data: createAutobuyData,
    isLoading: isLoadingCreateAutobuy,
    mutate: mutateCreateAutobuy,
  } = useMutationCreateAutobuy();

  const { data: searchAllList, isLoading: isLoadingSearchAllList } =
    useQueryFetchSearchAllProducts(searchPayload);

  useEffect(() => {
    if (searchAllList && !isLoadingSearchAllList) {
      setOptions(searchAllList);
    }
  }, [searchAllList, isLoadingSearchAllList]);

  useEffect(() => {
    if (createAutobuyData && !isLoadingCreateAutobuy) {
      onTradeCreated();
      toast.success(t('toastCreatedAutobuy'));
      sModalClose(modalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCreateAutobuy, createAutobuyData]);

  const { data: rawChartData, isLoading: isLoadingRawChart } =
    useQueryFetchPriceHistory(filterData, enabledFetchChart);

  const onChangeChartDateType = ({ target: { value } }: RadioChangeEvent) => {
    setChartDateType(value);
    if (typeof value !== 'string') {
      setFilterData({
        ...value,
        firstName: localData?.marketName.first,
        secondName: localData?.marketName.second,
        provider: localData?.provider,
        extra:
          localData?.provider === EGameType.CSGO ? sortQuality.title : null,
      });
    } else {
      setFilterData({
        firstName: localData?.marketName.first,
        secondName: localData?.marketName.second,
        provider: localData?.provider,
        extra:
          localData?.provider === EGameType.CSGO ? sortQuality.title : null,
      });
    }
  };

  useEffect(() => {
    if (rawChartData && !isLoadingRawChart) {
      setChartData(rawChartData);
    }
  }, [isLoadingRawChart, rawChartData]);

  const { xAxis, yAxis, ...chartConfig } = generalChartConfig;

  const config = {
    data: chartData,
    xField: 'Date',
    yField: 'scales',
    ...chartConfig,
    xAxis: { ...xAxis, tickCount: 7 },
    yAxis: { ...yAxis, tickCount: 6 },
    animation: false,
    tooltip: {
      ...generalTooltipConfig,
      customContent: (title, items) => {
        if (items && items.length) {
          const { data } = items[0];
          return (
            <div className="g2-tooltip__content">
              <div className="g2-tooltip__date">
                {dayjs(title).format('MMMM D, HH:mm')}
              </div>
              <div className="g2-tooltip__price">
                $ {formatPrice(Number(data.scales))}
              </div>
              <div className="g2-tooltip__count">
                {t('sold')} <span>{data.count}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  };

  useEffect(() => {
    if (data) {
      setLocalData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (localData) {
      setProduct({ ...localData, sale: 1, price: 0 });
      setModalType(ENewTradeViewModalType.Product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localData]);

  useEffect(() => {
    if (product && sortQuality) {
      setChartDateType(dateTypes[0].range);
      setFilterData({
        ...dateTypes[0].range,
        firstName: localData?.marketName.first,
        secondName: localData?.marketName.second,
        provider: localData?.provider,
        extra:
          localData?.provider === EGameType.CSGO ? sortQuality.title : null,
      });
      setEnabledFetchChart(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, sortQuality]);

  useEffect(() => {
    setSummary(product ? product.sale * product.price : 0);
  }, [product, chartData.length]);

  const onSearch = (value: string) => {
    setSearchPayload({ nameSearch: value });
  };

  const onSelect = (value: string) => {
    let selectedProduct = options.find(el => el.originalMarketName === value);
    setLocalData(selectedProduct);
    setModalType(ENewTradeViewModalType.Product);
  };

  const onChangePrice = value => {
    let { minMarketPrice } = product;
    minMarketPrice = minMarketPrice >= 0.03 ? minMarketPrice : 0.03;

    setProduct(prevData => {
      return { ...prevData, price: value >= minMarketPrice ? value : 0 };
    });
  };

  const onChangeQuality = value => {
    setSortQuality(value);
  };

  const onChangeCount = value => {
    if (value) {
      setProduct(prevData => {
        return { ...prevData, sale: value };
      });
    }
  };

  const onCreateTrade = () => {
    let payload = {};
    if (localData.provider === EGameType.CSGO) {
      payload = {
        price: {
          from: 0,
          to: Number(product.price),
        },
        tags: [
          {
            filterValues: [sortQuality.value],
            unitedOperator: 'or',
          },
        ],
        countToBuy: product.sale,
        nameSearch: product.marketName.second.length
          ? product.marketName.first + ' | ' + product.marketName.second
          : product.marketName.first,
        provider: localData.provider,
      };
    } else {
      payload = {
        price: {
          from: 0,
          to: Number(product.price),
        },
        countToBuy: product.sale,
        nameSearch: product.marketName.first,
        provider: localData.provider,
      };
    }
    mutateCreateAutobuy(payload);
  };

  return (
    <div
      className={
        'new-trade-modal' +
        (modalType === ENewTradeViewModalType.Autocomplete
          ? ' new-trade-modal--small'
          : ' new-trade-modal--big')
      }
    >
      <div className="new-trade-modal__content">
        <div className="new-trade-modal__title">{t('newRequest')}</div>
        {modalType === ENewTradeViewModalType.Autocomplete ? (
          <AppAutocomplete
            className="new-trade-modal__autocomplete"
            options={options}
            size={ESelectSizeType.Large}
            onSearch={onSearch}
            onSelect={onSelect}
          />
        ) : (
          <>
            {product && (
              <>
                <NewTradeModalCard
                  data={product}
                  price={product.price}
                  count={product.sale}
                  onPriceChange={onChangePrice}
                  onQualityChange={onChangeQuality}
                  onCountChange={onChangeCount}
                />
                <div className="new-trade-modal__chart-filter">
                  <Radio.Group
                    buttonStyle={ERadioButtonStyleType.Solid}
                    value={chartDateType}
                    size={ERadioButtonSizeType.Middle}
                    onChange={onChangeChartDateType}
                  >
                    {dateTypes?.length &&
                      dateTypes.map(item => {
                        return (
                          <Radio.Button value={item.range} key={item.value}>
                            {t(item.value)}
                          </Radio.Button>
                        );
                      })}
                  </Radio.Group>
                </div>
                <div className="new-trade-modal__chart">
                  {config?.data?.length ? (
                    <>
                      {config?.data?.length ? (
                        <>
                          {/*@ts-ignore*/}
                          <Area {...config} />
                        </>
                      ) : (
                        <EmptyCard
                          size={ECardSizeType.Minimal}
                          title={t('emptyChartTitle')}
                          description={t('emptyChartDesc')}
                        />
                      )}
                    </>
                  ) : (
                    <EmptyCard
                      size={ECardSizeType.Minimal}
                      title={t('emptyChartTitle')}
                      description={t('emptyChartDesc')}
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {modalType === ENewTradeViewModalType.Product && (
        <div className="new-trade-modal__footer">
          <div className="new-trade-modal__summary">
            <div className="new-trade-modal__summary-title">
              {t('requestFor')} <span>$ {formatPrice(Number(summary))}</span>
            </div>
            <div className="new-trade-modal__summary-text">
              {t('withoutCommission')}
            </div>
          </div>
          <Button
            type={EButtonTypeType.Primary}
            size={EButtonSizeType.Large}
            className="new-trade-modal__btn"
            disabled={isLoadingCreateAutobuy || !product?.price}
            onClick={() => {
              onCreateTrade();
            }}
          >
            {t('productCreateRequest')}
          </Button>
        </div>
      )}
    </div>
  );
};

export { NewTradeModal };
