// core
import React, { useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MEDIA } from 'utils/constants';
import { useWindowSize } from 'react-use';
import { useLocation, useNavigate } from 'react-router-dom';
import { objectToString } from 'utils/commons';
// locales
import { useTranslation } from 'react-i18next';
// hooks
import {
  useInfinityQueryMarketGetItems,
  useQueryAvailableFilters,
} from 'hooks/api';
import { useAsideScrollPosition } from 'hooks/general/useAsideScrollPosition';
// store
import { useAppStore, useExtStore } from 'store';

// types
import { EGridType } from 'types/units';
import {
  ECardColorType,
  ECardSizeType,
} from 'components/features/Cards/EmptyCard/types';
import { EProductCardType } from 'components/features/Cards/ProductCard/type';
import { ELoaderTypeType } from 'components/atoms/AppLoader/types';
import {
  EMarketFilterCategoryType,
  EMarketFilterUnitedType,
  EMarketFilterUrlType,
  ISortOption,
} from 'types/models';

// components(atoms)
import { CatalogSEO } from './SEO';
import { CatalogHead } from './CatalogHead';
import { CatalogAside } from './CatalogAside';
import { EmptyCard, ProductCard } from 'components/features';
import { AppLoader } from 'components/atoms';
import { ScrollToTop } from '../../../components/atoms/ScrollToTop';
// styles
import './index.scss';

// data
import {
  getIniApiAttributes,
  getInitSortAttributes,
  sortOptions,
  updateUrlParams,
} from './data';
import { isEqual } from 'lodash';

// func
const Catalog = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const location = useLocation();
  const navigate = useNavigate();

  const { catalogGrid, gameType, catalogStack, headerTopOffset } =
    useAppStore();
  const { isExtOnline } = useExtStore();

  const [enabled, setEnabled] = useState(false);
  const [params, setParams] = useState<any>(null);

  const [filterAttributes, setFilterAttributes] = useState<null | object>(null);
  const [isResetFilterAttributes, setIsResetFilterAttributes] =
    useState<boolean>(false);
  const [categoriesAttributes, setCategoriesAttributes] = useState<
    null | object
  >(null);
  const [searchAttributes, setSearchAttributes] = useState<string>('');
  const [sortAttributes, setSortAttributes] = useState<ISortOption>(
    getInitSortAttributes(location.search),
  );

  const [isLocalExtOnline, setIsExtOnline] = useState<boolean>(isExtOnline);

  const asideRef = useRef<any>(null);
  useAsideScrollPosition(asideRef, width > MEDIA.M1280 ? 66 : 48);

  const [disabledResetButton, setDisabledResetButton] =
    useState<boolean>(false);

  const {
    data: filtersData,
    isLoading: isLoadingFilters,
    remove: filtersRemove,
  } = useQueryAvailableFilters({ provider: gameType });

  const {
    marketProducts,
    isLoading: isLoadingMarketGetItems,
    isFetchedAfterMount: isFetchedAfterMountMarketGetItems,
    isFetched: isFetchedMarketGetItems,
    hasNextPage,
    isFetchingNextPage: isFetchingNextMarketGetItems,
    fetchNextPage,
    refetch: refetchMarket,
  } = useInfinityQueryMarketGetItems({ params, enabled });

  useEffect(() => {
    return () => {
      filtersRemove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterAttributes && sortAttributes && categoriesAttributes) {
      setDisabledResetButton(
        isEqual(filterAttributes, cleanedFilters()) &&
          isEqual(sortAttributes, sortOptions[0]) &&
          isEqual(categoriesAttributes, cleanedCategories()) &&
          searchAttributes === '',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterAttributes,
    sortAttributes,
    categoriesAttributes,
    searchAttributes,
  ]);

  useEffect(() => {
    if (isFetchedMarketGetItems) {
      window['prerenderReady'] = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchedMarketGetItems]);

  useEffect(() => {
    if (filtersData) {
      let { filterCollection, categoriesCollection, searchCollection } =
        getIniApiAttributes(filtersData, location.search, gameType);

      setFilterAttributes(filterCollection);
      setCategoriesAttributes(categoriesCollection);
      setSearchAttributes(searchCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersData]);

  useEffect(() => {
    if (filterAttributes) {
      //---change url
      let objectUrlParams = updateUrlParams(
        filterAttributes,
        categoriesAttributes,
        sortAttributes,
        searchAttributes,
        gameType,
      );

      // @ts-ignore
      if (Object.keys(objectUrlParams).length) {
        navigate(
          {
            pathname: '/market',
            search: `?${objectToString(objectUrlParams)}`,
          },
          { replace: true },
        );
      } else {
        navigate({ pathname: '/market' }, { replace: true });
      }

      //---create api format

      let {
        Price,
        Stickers,
        Float,
        CountSticker,
        CheaperThanSteam,
        ...attributes
      }: any = filterAttributes;

      let steamParams = {
        cheaperThanSteam: !!CheaperThanSteam?.values[0],
      };

      let priceParams = Price
        ? {
            price: { from: Price.values[0], to: Price.values[1] },
          }
        : {};

      let tags = Object.values(attributes).reduce<Record<any, any>>(
        (acc, item: any) => {
          let { values } = item;
          if (values.length) {
            return [
              ...(acc as []),
              {
                filterValues: values,
                unitedOperator: EMarketFilterUnitedType.Or,
              },
            ];
          } else {
            return acc;
          }
        },
        [],
      );

      let countStickerParams = CountSticker?.values?.length
        ? {
            countSticker: Number(CountSticker.values[0]),
          }
        : {};

      let stickersParams = Stickers?.values?.length
        ? {
            stickers: [
              {
                filterValues: Stickers.values,
                unitedOperator: EMarketFilterUnitedType.Or,
              },
            ],
          }
        : {};

      //---categories
      // @ts-ignore
      Object.values(categoriesAttributes).forEach<Record<any, any>>(
        (item: any) => {
          Object.keys(item.dataValues).forEach(key => {
            let categoryValues = item.dataValues[key];

            if (categoryValues) {
              let filterValues: any[] = [];
              if (categoryValues.indexOf(EMarketFilterCategoryType.All) > -1) {
                let parts = categoryValues.split('_');
                let group = item.possibilityGroups.find(
                  i => i.sku === parts[1],
                );
                filterValues = group.possibilityValues.at(-1).valueExtra;
              } else {
                let categoryValuesArray = categoryValues.split(',');
                filterValues = [...categoryValuesArray];
              }

              tags = [
                ...(tags as []),
                {
                  filterValues,
                  unitedOperator: EMarketFilterUnitedType.Or,
                },
              ];
            }
          });
        },
      );

      let tagParams = tags.length ? { tags } : {};
      //---sort
      let sortParams = sortAttributes?.typeValue
        ? {
            sort: { [sortAttributes.type as string]: sortAttributes.typeValue },
          }
        : {};

      //---search
      let searchParams = searchAttributes
        ? { nameSearch: searchAttributes }
        : {};

      setParams({
        provider: gameType,
        group: catalogStack,
        ...tagParams,
        ...priceParams,
        ...steamParams,
        ...countStickerParams,
        ...stickersParams,
        ...sortParams,
        ...searchParams,
      });
      setEnabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(filterAttributes),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(categoriesAttributes),
    sortAttributes,
    searchAttributes,
    catalogStack,
  ]);

  useEffect(() => {
    if (isExtOnline !== isLocalExtOnline) {
      setIsExtOnline(isExtOnline);
      refetchMarket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExtOnline]);

  const onChangeFilters = (filters: any) => {
    setFilterAttributes({ ...filterAttributes, ...filters });
  };

  const onChangeCategory = (categories: any) => {
    setCategoriesAttributes(categories);
  };
  const onChangeSort = (data: any) => {
    setSortAttributes(data);
  };

  const onChangeSearch = (data: any) => {
    if (data === '') {
      if (width < MEDIA.M1024) {
        setSearchAttributes('');
      }
      setIsResetFilterAttributes(true);
      setTimeout(() => {
        setIsResetFilterAttributes(false);
      }, 300);
    } else {
      setSearchAttributes(data);
    }
  };

  const cleanedFilters = () => {
    return Object.keys(filterAttributes!).reduce((acc, key) => {
      let { urlType, sku, possibilityValues } = filterAttributes![key];

      return {
        ...acc,
        [sku]: {
          ...filterAttributes![key],
          values:
            urlType === EMarketFilterUrlType.Range
              ? [possibilityValues[0].value, possibilityValues[1].value]
              : [],
        },
      };
    }, {} as object);
  };

  const cleanedCategories = () => {
    return Object.keys(categoriesAttributes!).reduce((acc, key) => {
      let { sku } = categoriesAttributes![key];

      return {
        ...acc,
        [sku]: {
          ...categoriesAttributes![key],
          dataValues: {},
        },
      };
    }, {} as object);
  };
  const onResetAll = () => {
    setFilterAttributes(cleanedFilters());
    setSortAttributes(sortOptions[0]);
    setCategoriesAttributes(cleanedCategories());
    setSearchAttributes('');
  };

  const isShowCatalogLoader = useMemo(() => {
    return (
      (!isFetchedAfterMountMarketGetItems || isLoadingMarketGetItems) &&
      !isFetchingNextMarketGetItems
    );
  }, [
    isLoadingMarketGetItems,
    isFetchingNextMarketGetItems,
    isFetchedAfterMountMarketGetItems,
  ]);

  return (
    <>
      <CatalogSEO />
      <div className="catalog">
        {isLoadingFilters || filterAttributes === null ? (
          <AppLoader type={ELoaderTypeType.Global} />
        ) : (
          <div className="catalog__grid">
            {width > MEDIA.M1024 && (
              <div className="catalog__aside" ref={asideRef}>
                <CatalogAside
                  isOuterReset={isResetFilterAttributes}
                  filters={filterAttributes}
                  onReset={onResetAll}
                  onChange={onChangeFilters}
                  isDisabledReset={disabledResetButton}
                />
              </div>
            )}
            <div className="catalog__main">
              <CatalogHead
                sort={sortAttributes}
                categories={categoriesAttributes}
                filters={filterAttributes}
                search={searchAttributes}
                onChangeCategory={onChangeCategory}
                onChangeSort={onChangeSort}
                onChangeFilter={onChangeFilters}
                onReset={onResetAll}
                onChangeSearch={onChangeSearch}
                isDisabledReset={disabledResetButton}
              />

              {isShowCatalogLoader ? (
                <div className="catalog__refetch-loader">
                  <AppLoader type={ELoaderTypeType.Relative} />
                </div>
              ) : (
                <>
                  {marketProducts?.length > 0 ? (
                    <>
                      <InfiniteScroll
                        dataLength={marketProducts.length}
                        next={() => {
                          if (hasNextPage) fetchNextPage();
                        }}
                        hasMore={!!hasNextPage}
                        loader={
                          isFetchingNextMarketGetItems && (
                            <div className="catalog__scroll-loader">
                              <AppLoader type={ELoaderTypeType.Relative} />
                            </div>
                          )
                        }
                      >
                        <div
                          className={
                            'catalog__main-grid' +
                            (catalogGrid === EGridType.Small
                              ? ' catalog__main-grid--compact'
                              : '')
                          }
                        >
                          {marketProducts.map((item, index) => (
                            <ProductCard
                              key={`${item.id}-${index}`}
                              cardType={EProductCardType.Market}
                              gameType={gameType}
                              size={catalogGrid}
                              item={item}
                            />
                          ))}
                        </div>
                      </InfiniteScroll>
                    </>
                  ) : (
                    <div className="catalog__empty">
                      <div className="catalog__empty--card">
                        <EmptyCard
                          size={ECardSizeType.Default}
                          color={ECardColorType.Dark}
                          title={t('emptyMarketTitle')}
                          description={t('emptyMarketDesc')}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        <ScrollToTop />
      </div>
    </>
  );
};

export { Catalog };
