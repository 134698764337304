import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { MEDIA } from '../../../utils/constants';
// utils

// locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';

//hooks

// types
import { EButtonSizeType } from 'types/units';
// components(atoms)
import { Button, Drawer } from 'antd';
import { ArrowLeftIcon, PurpleGlowIcon, CloseIcon } from 'components/icons';
import { DashboardSEO } from './SEO';
import { DashboardNavigation } from './DashboardNavigation';

// styles
import './index.scss';

// data

// func

const Dashboard = () => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { drawer, headerTopOffset, sDrawerClose } = useAppStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    sDrawerClose('dashboardDrawer');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const onBackButton = () => {
    navigate('/');
  };
  const onCloseDrawer = () => {
    sDrawerClose('dashboardDrawer');
  };

  return (
    <>
      <DashboardSEO />
      <div className="dashboard">
        <div className="dashboard__wrap">
          <div className="dashboard__nav">
            <div className="dashboard__nav--links">
              <div className="dashboard__nav--links-back">
                <Button
                  size={EButtonSizeType.Middle}
                  ghost
                  className={'ant-btn-ghost--grey'}
                  onClick={() => {
                    onBackButton();
                  }}
                >
                  <ArrowLeftIcon />
                  {t('back')}
                </Button>
              </div>
              <DashboardNavigation />
            </div>
          </div>
          <div className="dashboard__body">
            <Outlet />
            <div className="dashboard__body--bg">
              <PurpleGlowIcon />
            </div>
          </div>
        </div>
        <Drawer
          title={t('settings')}
          placement={'left'}
          destroyOnClose={true}
          closable={true}
          onClose={onCloseDrawer}
          open={drawer['dashboardDrawer']}
          getContainer={false}
          rootClassName={'dashboard__drawer'}
          width={width > MEDIA.M768 ? 320 : '100%'}
          closeIcon={<CloseIcon />}
          rootStyle={{ height: `calc(100vh - ${headerTopOffset}px)` }}
        >
          <DashboardNavigation />
        </Drawer>
      </div>
    </>
  );
};
export { Dashboard };
