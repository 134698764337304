// core
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Radio, RadioChangeEvent } from 'antd';
import { Area } from '@ant-design/plots';
import dayjs from 'dayjs';
// utils
import { useTranslation } from 'react-i18next';
// store
import { useAppStore, useExtStore, useInventoryStore } from 'store';

//hooks
import {
  useMutationInventoryItemsToMarket,
  useQueryFetchPriceHistory,
} from '../../../../hooks/api';

// types
import {
  EButtonSizeType,
  EButtonTypeType,
  EInventoryModalSellType,
  ERadioButtonSizeType,
  ERadioButtonStyleType,
} from 'types/units';
import { IPropsType } from './types';
// components
import { LightningIcon } from 'components/icons';
import { EmptyCard, SellModalCard } from 'components/features';

// styles
import './index.scss';

// data
import {
  generalChartConfig,
  generalTooltipConfig,
} from '../../../../config/charts';
import { dateTypes } from './data';

import { toast } from 'react-toastify';
import { formatPrice } from '../../../../utils/commons';
import { ECardSizeType } from '../../Cards/EmptyCard/types';

const SellProductModal = ({ modalId, onExposedSingle }: IPropsType) => {
  const { t } = useTranslation();
  const { gameType, sModalOpen, sModalClose } = useAppStore();
  const { isExtInstalled } = useExtStore();
  const {
    inventoryExposeModal,
    inventoryExposeModalType,
    inventoryExpose,
    sSetInventoryExpose,
  } = useInventoryStore();

  const [data, setData] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(
    inventoryExposeModal?.price ? inventoryExposeModal?.price : 0,
  );
  const [product, setProduct] = useState<any>(null);

  const [chartDateType, setChartDateType] = useState(dateTypes[0].range);
  const [filterData, setFilterData] = useState<any>(null);

  const {
    mutate: mutateInventoryToMarket,
    isLoading: isLoadingInventoryToMarket,
    isSuccess: isSuccessInventoryToMarket,
  } = useMutationInventoryItemsToMarket();

  const [enabledFetchChart, setEnabledFetchChart] = useState(false);

  const { data: rawChartData, isLoading: isLoadingRawChart } =
    useQueryFetchPriceHistory(filterData, enabledFetchChart);

  useEffect(() => {
    if (!isLoadingRawChart && rawChartData) {
      setData(rawChartData);
    }
  }, [isLoadingRawChart, rawChartData]);

  const { xAxis, yAxis, ...chartConfig } = generalChartConfig;

  const config = {
    data,
    xField: 'Date',
    yField: 'scales',
    ...chartConfig,
    xAxis: { ...xAxis, tickCount: 7 },
    yAxis: { ...yAxis, tickCount: 6 },
    animation: false,
    tooltip: {
      ...generalTooltipConfig,
      customContent: (title, items) => {
        if (items && items.length) {
          const { data } = items[0];
          return (
            <div className="g2-tooltip__content">
              <div className="g2-tooltip__date">
                {dayjs(title).format('MMMM D, HH:mm')}
              </div>
              <div className="g2-tooltip__price">{data.scales}</div>
              <div className="g2-tooltip__count">
                Продано <span>{data.count}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  };

  useEffect(() => {
    setProduct(inventoryExposeModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProduct(inventoryExposeModal);
    if (product) {
      setFilterData({
        ...dateTypes[0].range,
        firstName: product?.marketName.first,
        secondName: product?.marketName.second,
        provider: gameType,
        extra: product?.exterior,
      });
      setEnabledFetchChart(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    if (!isLoadingInventoryToMarket && isSuccessInventoryToMarket) {
      onExposedSingle();
      toast.success(t('toastSuccessPlacementOnMarket'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInventoryToMarket, isSuccessInventoryToMarket]);

  const isEditFlow = useMemo(() => {
    return (
      inventoryExpose.length > 1 &&
      inventoryExposeModalType === EInventoryModalSellType.InventoryExpose
    );
  }, [inventoryExposeModalType, inventoryExpose]);

  const onChangeChartDateType = ({ target: { value } }: RadioChangeEvent) => {
    setChartDateType(value);
    if (typeof value !== 'string') {
      setFilterData({
        ...value,
        firstName: product?.marketName.first,
        secondName: product?.marketName.second,
        provider: gameType,
        extra: product?.exterior,
      });
    } else {
      setFilterData({
        firstName: product?.marketName.first,
        secondName: product?.marketName.second,
        provider: gameType,
        extra: product?.exterior,
      });
    }
  };

  const onPriceChange = price => {
    setCurrentPrice(price);
  };

  const onEditProduct = () => {
    let index = inventoryExpose.findIndex(
      item => item.id === inventoryExposeModal.id,
    );

    if (index > -1) {
      inventoryExpose[index] = { ...product, price: currentPrice };
      sSetInventoryExpose(inventoryExpose);
      sModalClose(modalId);
    }
  };

  const onSellProduct = () => {
    if (isExtInstalled) {
      mutateInventoryToMarket([
        {
          itemId: inventoryExposeModal.id,
          price: currentPrice,
        },
      ]);
    } else {
      sModalOpen('installExtension');
    }
  };

  return (
    <div className="sell-product-modal">
      {product && (
        <>
          <div className="sell-product-modal__content">
            <div className="sell-product-modal__title">{t('sellItem')}</div>
            <SellModalCard
              data={product}
              currentPrice={currentPrice}
              onPriceChange={onPriceChange}
            />
            <div className="sell-product-modal__chart-filter">
              <Radio.Group
                buttonStyle={ERadioButtonStyleType.Solid}
                value={chartDateType}
                size={ERadioButtonSizeType.Middle}
                onChange={onChangeChartDateType}
              >
                {dateTypes?.length &&
                  dateTypes.map(item => {
                    return (
                      <Radio.Button value={item.range} key={item.value}>
                        {t(item.value)}
                      </Radio.Button>
                    );
                  })}
              </Radio.Group>
            </div>
            <div className="sell-product-modal__chart">
              {config?.data?.length ? (
                <>
                  {/*@ts-ignore*/}
                  <Area {...config} />
                </>
              ) : (
                <EmptyCard
                  size={ECardSizeType.Minimal}
                  title={t('emptyChartTitle')}
                  description={t('emptyChartDesc')}
                />
              )}
            </div>
          </div>
          <div className="sell-product-modal__footer">
            <div className="sell-product-modal__summary">
              <div className="sell-product-modal__summary-title">
                {t('requestFor')} <span>${formatPrice(currentPrice, 2)}</span>
              </div>
              <div className="sell-product-modal__summary-text">
                {product.originalMarketName}
              </div>
            </div>

            {isEditFlow ? (
              <Button
                type={EButtonTypeType.Primary}
                size={EButtonSizeType.Large}
                disabled={isLoadingInventoryToMarket || !currentPrice}
                className="sell-product-modal__btn"
                onClick={() => {
                  onEditProduct();
                }}
              >
                {t('editItem')}
              </Button>
            ) : (
              <Button
                type={EButtonTypeType.Primary}
                size={EButtonSizeType.Large}
                disabled={isLoadingInventoryToMarket || !currentPrice}
                className="sell-product-modal__btn"
                onClick={() => {
                  onSellProduct();
                }}
              >
                {t('sellItem')}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export { SellProductModal };
