import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Table, Checkbox, Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
// locale
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';
import {
  useQueryFetchPendingInventory,
  useMutationRemoveFromMarket,
  useMutationInventoryItemsToMarket,
  useQueryFetchProfileInfo,
  useQueryFetchProductInfo,
} from 'hooks/api';
import {
  useSocketDispatch,
  useSocketSubscribe,
} from '../../../../hoc/SocketProvider';
// types
import { EFloatRangeSizeType } from 'components/atoms/FloatRange/types';
import { EButtonSizeType, EButtonTypeType, EInputSizeType } from 'types/units';
import { EGameType } from 'types/models';
import {
  ECardColorType,
  ECardSizeType,
} from 'components/features/Cards/EmptyCard/types';
import { ELoaderTypeType } from 'components/atoms/AppLoader/types';
import { ENotificationsType, ESocketEventType } from 'types/api';
// components
import { AppLoader, FloatRange, ModalTeleport } from 'components/atoms';
import {
  ArrowLeftLightIcon,
  ArrowRightIcon,
  EditIcon,
  StatIcon,
  SuccessIcon,
  TrashIcon,
} from 'components/icons';
import {
  PriceModal,
  EmptyCard,
  TableCellImage,
  TableCellStickers,
  RemoveTradeModal,
  RequestTradeModal,
} from 'components/features';
import { InventoryHead } from '../InventoryHead';
// styles
import './index.scss';

//data
import {
  formatPrice,
  objectToString,
  queryStringToObject,
} from '../../../../utils/commons';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';

import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { queryClient } from '../../../../config/queryClient';
import { TableCellName } from '../../../../components/features/TableCells/TableCellName';

//data
const transformData = arr => {
  return arr.map(el => {
    let {
      price,
      minMarketPrice,
      minAutoBuyPrice,
      bargainsCount,
      sku,
      image,
      preview,
      name,
      float,
      shortenExterior,
      stickers,
      status,
      marketName,
      originalMarketName,
      provider,
      gems,
      exterior,
    } = el;
    return {
      id: sku,
      checked: false,
      bargainsCount,
      status,
      price,
      minMarketPrice,
      minAutoBuyPrice,
      img: image ? image : preview,
      name,
      short: shortenExterior,
      float,
      marketName,
      provider,
      originalMarketName,
      exterior,
      stickers,
      gems,
    };
  });
};
const InventorySelling = () => {
  const { t } = useTranslation();
  const inputRef = useRef<any>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { gameType, sModalOpen } = useAppStore();
  const [enabled, setEnabled] = useState(false);
  const [enabledNotifications, setEnabledNotifications] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [removeDeal, setRemoveDeal] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [editingId, setEditingId] = useState<any>(null);

  const [enabledFetchProduct, setEnabledFetchProduct] =
    useState<boolean>(false);
  const [showProductSku, setShowProductSku] = useState<any>('');
  const [isLocalLoading, setIsLocalLoading] = useState<boolean>(true);

  const [query, setQuery] = useQueryParams({
    show: withDefault(StringParam, undefined),
  });

  const { show } = query;

  const { refetch: refetchProfile } = useQueryFetchProfileInfo(true);

  const { data: productData, isLoading: isLoadingProductData } =
    useQueryFetchProductInfo(showProductSku, enabledFetchProduct);

  useEffect(() => {
    if (show && show.length) {
      setShowProductSku(show);
      setEnabledFetchProduct(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (!isLoadingProductData && productData && showProductSku?.length) {
      if (productData.status === 'Active') {
        setSelectedItem(transformData([productData])[0]);
        sModalOpen('requestTrade');
      } else {
        toast.error(t('noneBargainError'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData, isLoadingProductData]);

  const [payload, setPayload] = useState<any>({
    page: 1,
    take: 10,
    provider: gameType,
    status: 'Active',
    nameSearch: undefined,
    sort: undefined,
  });

  const { data: profileData, isLoading: isLoadingProfileData } =
    useQueryFetchProfileInfo(false);

  const {
    data: pendingInventoryData,
    isLoading: isLoadingPendingInventory,
    refetch: refetchPendingInventory,
  } = useQueryFetchPendingInventory({
    payload: payload,
    enabled,
  });

  const {
    data: removeFromMarketData,
    isLoading: isLoadingRemoveFromMarket,
    mutate: mutateRemoveFromMarket,
  } = useMutationRemoveFromMarket();

  const {
    data: updatePriceData,
    isLoading: isLoadingUpdatePrice,
    mutate: mutateUpdatePrice,
  } = useMutationInventoryItemsToMarket();

  const { isConnected, socketEmit } = useSocketDispatch();

  const onSocketHandler = data => {
    if (
      data &&
      (data.type === ENotificationsType.ItemPropose ||
        data.type === ENotificationsType.ItemRequestToAccept)
    ) {
      refetchPendingInventory();
    }
  };

  useSocketSubscribe(ESocketEventType.Notification, onSocketHandler);

  useEffect(() => {
    if (profileData && !isLoadingProfileData) {
      setEnabledNotifications(true);
    }
  }, [profileData, isLoadingProfileData]);

  useEffect(() => {
    let { page } = queryStringToObject(location.search);

    let pageUrl: string = page && Number(page) > 1 ? page.toString() : '1';

    setPayload({ ...payload, page: pageUrl });
    setEnabled(true);
    refetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //---change url

    // @ts-ignore
    if (payload?.page && Number(payload.page) > 1) {
      navigate(
        {
          pathname: '/inventory/sales',
          search: `?${objectToString({ page: payload.page })}`,
        },
        { replace: true, state: { preventScrollReset: enabled } },
      );
    } else {
      navigate(
        { pathname: '/inventory/sales' },
        { replace: true, state: { preventScrollReset: enabled } },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    if (pendingInventoryData && !isLoadingPendingInventory) {
      if (pendingInventoryData) {
        let { meta, data } = pendingInventoryData;
        if (meta.page === 1 || (meta.page > 1 && data.length > 0)) {
          setTableData(transformData(data));
          setTimeout(() => {
            setIsLocalLoading(false);
          }, 100);
        } else {
          setPayload({
            ...payload,
            page: Math.ceil(meta.itemsCount / meta.take),
          });
        }
      }
    } else if (isLoadingPendingInventory) {
      setIsLocalLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingInventoryData, isLoadingPendingInventory]);

  useEffect(() => {
    return () => {
      setTableData([]);
      queryClient.invalidateQueries(['useGetPendInventory']);
    };
  }, []);

  useEffect(() => {
    if (removeFromMarketData && !isLoadingRemoveFromMarket) {
      toast.success(t('toastSuccessDeletionPending'));
      refetchPendingInventory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeFromMarketData, isLoadingRemoveFromMarket]);

  useEffect(() => {
    if (updatePriceData && !isLoadingUpdatePrice) {
      setEditingId(null);
      refetchPendingInventory();
      toast.success(t('toastSuccessChangedAutoPrice'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePriceData, isLoadingUpdatePrice]);

  const onRemoveTradeDeal = () => {
    if (removeDeal) {
      mutateRemoveFromMarket({ items: [{ itemId: removeDeal }] });
      setRemoveDeal(null);
    } else {
      let filteredTableData = tableData.filter(el => el.checked);
      let payload = filteredTableData.map(el => {
        return { itemId: el.id };
      });
      mutateRemoveFromMarket({ items: payload });
      setRemoveDeal(null);
    }
  };
  const onTableChange = pagination => {
    setPayload({ ...payload, page: pagination.current });
  };

  const onShowPriceStat = data => {
    setSelectedItem(data);
    sModalOpen('priceTrade');
  };

  const onAcceptProduct = data => {
    setRemoveDeal(data.id);
    sModalOpen('removeTrade');
    // setTableData(list => list.filter(item => item.id !== data.id));
  };

  const onChangeCheckbox = data => {
    let clonedTableData = cloneDeep(tableData);
    clonedTableData.find(el => el.id === data).checked = !clonedTableData.find(
      el => el.id === data,
    ).checked;
    setTableData(clonedTableData);
  };

  const onSelectedAll = () => {
    let clonedTableData = cloneDeep(tableData);
    let allChecked = clonedTableData.every(el => el.checked);
    clonedTableData.forEach(el => {
      el.checked = !allChecked;
    });
    setTableData(clonedTableData);
  };
  const onRemovedAll = () => {
    if (tableData.some(el => el.checked)) {
      sModalOpen('removeTrade');
    }
  };

  const onEditTradeDeal = data => {
    setEditingId(data.id);
  };

  const onSaveTradeDeal = data => {
    let { value } = inputRef!.current!.input;
    mutateUpdatePrice([{ itemId: data.id, price: Number(value) }]);
  };

  const refreshTable = () => {
    refetchPendingInventory();
  };

  const onShowOffers = data => {
    setSelectedItem(data);
    sModalOpen('requestTrade');
  };

  const onChangeSearch = (data: any) => {
    setPayload({ ...payload, nameSearch: data });
  };
  const onChangeSort = (data: any) => {
    setPayload({
      ...payload,
      sort: data.type ? { [data.type]: data.typeValue } : undefined,
    });
  };

  const renderColumns = [
    {
      key: 'key',
      dataIndex: 'key',
      render: (_, record) => (
        <div className="ant-table-cell__check">
          <Checkbox
            onChange={() => {
              onChangeCheckbox(record.id);
            }}
            checked={record.checked}
          ></Checkbox>
        </div>
      ),
    },
    {
      key: 'img',
      dataIndex: 'img',
      render: (_, record) => (
        <TableCellImage
          productId={record.id}
          src={record.img}
          gameType={record.provider}
          alt={''}
        />
      ),
    },
    {
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => (
        <TableCellName
          first={record.marketName.first}
          second={record.marketName.second}
          originalMarketName={record.originalMarketName}
          productId={record.id}
        />
      ),
    },
    {
      key: 'float',
      dataIndex: 'float',
      render: (_, record) => (
        <>
          {record.provider === EGameType.CSGO && (
            <>
              {record.float > 0 && (
                <div className="ant-table-cell__float">
                  {record.short}
                  <span>{record.float}</span>
                </div>
              )}
              <FloatRange
                isTooltip={!record.float}
                size={EFloatRangeSizeType.Small}
                maxValue={record.float}
              />
            </>
          )}
        </>
      ),
    },
    {
      key: 'stickers',
      dataIndex: 'stickers',
      width: '50%',
      render: (_, record) => (
        <TableCellStickers
          gameType={record.provider}
          collection={
            record.provider === EGameType.CSGO ? record.stickers : record.gems
          }
        />
      ),
    },
    {
      key: 'minMarketPrice',
      dataIndex: 'minMarketPrice',
      render: minMarketPrice => (
        <div className="ant-table-cell__nowrap">
          {t('minPrice')}:{' '}
          <span className="ant-table-cell__value">
            $ {formatPrice(minMarketPrice)}
          </span>
        </div>
      ),
    },
    {
      key: 'price',
      dataIndex: 'price',
      render: (_, record) => {
        if (record.id === editingId) {
          return (
            <div className="ant-table-cell-editable">
              {/*<Form.Item>*/}
              <Input
                ref={inputRef}
                size={EInputSizeType.Large}
                prefix={'$'}
                suffix={
                  <Button
                    type={EButtonTypeType.Default}
                    className="ant-btn-default--light"
                    icon={<SuccessIcon />}
                    disabled={isLoadingUpdatePrice}
                    onClick={() => {
                      onSaveTradeDeal(record);
                    }}
                  />
                }
                defaultValue={record.price}
              />
              {/*</Form.Item>*/}
            </div>
          );
        } else {
          return (
            <div className="deals-autobuy__table-sale">
              <div className="ant-table-cell__nowrap">
                {t('yourPrice')}:{' '}
                <span className="ant-table-cell__value">
                  $ {formatPrice(record.price)}
                </span>
              </div>
              <Button
                disabled={editingId}
                size={EButtonSizeType.Middle}
                icon={<EditIcon />}
                type={EButtonTypeType.Text}
                onClick={() => onEditTradeDeal(record)}
              />
            </div>
          );
        }
      },
    },
    {
      key: 'minAutoBuyPrice',
      dataIndex: 'minAutoBuyPrice',
      render: minAutoBuyPrice => (
        <div className="ant-table-cell__nowrap">
          {t('tradeNewPrice')}:{' '}
          <span className="ant-table-cell__value">
            $ {formatPrice(minAutoBuyPrice)}
          </span>
        </div>
      ),
    },
    {
      key: 'stat',
      render: (_, record) => (
        <Button
          type={EButtonTypeType.Text}
          className={'ant-btn-text--gray'}
          icon={<StatIcon />}
          onClick={() => onShowPriceStat(record)}
        />
      ),
    },
    {
      key: 'offers',
      render: (_, record) => (
        <div
          onClick={() => onShowOffers(record)}
          className={
            'deals-sale__table-offers' +
            (record.offers > 0 ? ' deals-sale__table-offers--active' : '')
          }
        >
          {record.offers} {t('tradesOffers')}
        </div>
      ),
    },
    {
      key: 'action',
      render: (_, record) => (
        <Button
          size={EButtonSizeType.Middle}
          className={'ant-btn-text--error'}
          type={EButtonTypeType.Text}
          onClick={() => onAcceptProduct(record)}
        >
          <TrashIcon />
        </Button>
      ),
    },
  ];

  return (
    <div className="inventory">
      <>
        <InventoryHead
          isInventory={false}
          categories={null}
          search={payload.nameSearch}
          sort={null}
          isSteamUpdateProcessed={undefined}
          onChangeCategory={undefined}
          onChangeSort={onChangeSort}
          onReset={undefined}
          onChangeSearch={onChangeSearch}
          onSteamReload={undefined}
          isInventorySale={true}
          searchType={'Active'}
          onSelectAll={() => {
            onSelectedAll();
          }}
          onRemoveSelected={() => {
            onRemovedAll();
          }}
        />
        <div className="inventory-pending">
          {isLocalLoading ? (
            <div className="inventory-products__refetch-loader">
              <AppLoader type={ELoaderTypeType.Relative} />
            </div>
          ) : (
            <>
              <Table
                showHeader={false}
                className="inventory-pending__table"
                columns={renderColumns}
                onChange={pagination => {
                  onTableChange(pagination);
                }}
                // @ts-ignore
                dataSource={tableData}
                rowKey="id"
                scroll={{ x: '1200px' }}
                locale={{
                  emptyText: (
                    <div className="ant-table-cell__table-empty">
                      <EmptyCard
                        size={ECardSizeType.Small}
                        color={ECardColorType.Light}
                        title={t('emptyRequestTitle')}
                        description={t('emptyRequestDesc')}
                      />
                    </div>
                  ),
                }}
                pagination={{
                  position: ['bottomCenter'],
                  current: pendingInventoryData?.meta?.page,
                  pageSize: pendingInventoryData?.meta?.take,
                  total: pendingInventoryData?.meta?.itemsCount,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                  itemRender: (page, type, originalElement) => {
                    if (type === 'next') {
                      return <ArrowRightIcon />;
                    } else if (type === 'prev') {
                      return <ArrowLeftLightIcon />;
                    }
                    return originalElement;
                  },
                }}
              />
            </>
          )}
        </div>
      </>

      <ModalTeleport
        centered
        modalId={'priceTrade'}
        width={1330}
        modalClass={'price-modal__wrapper'}
      >
        <PriceModal product={selectedItem} modalId={'priceTrade'} />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'removeTrade'}
        width={453}
        modalClass={'remove-save-modal__wrapper'}
      >
        <RemoveTradeModal
          modalId={'removeTrade'}
          onRemove={onRemoveTradeDeal}
          onClose={() => {
            setRemoveDeal(null);
          }}
        />
      </ModalTeleport>
      <ModalTeleport
        centered
        modalId={'requestTrade'}
        width={1500}
        modalClass={'request-trade-modal__wrapper'}
        onClose={refreshTable}
      >
        <RequestTradeModal
          onShowChart={name => {
            setSelectedItem(name);
          }}
          onRemoveItem={id => {
            setRemoveDeal(id);
          }}
          onClose={refreshTable}
          item={selectedItem}
          modalId={'requestTrade'}
        />
      </ModalTeleport>
    </div>
  );
};

export { InventorySelling };
