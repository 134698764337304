import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';
// utils
import { formatPartsPrice, formatPrice } from '../../../../utils/commons';
// locales
import { useTranslation } from 'react-i18next';

//hooks
import {
  useQueryFetchReferralLink,
  useQueryFetchReferralChart,
  useMutationSetReferral,
} from '../../../../hooks/api';

// store

// types
import { EButtonSizeType, ESelectSizeType } from 'types/units';
import { ELoaderTypeType } from '../../../../components/atoms/AppLoader/types';

// components(atoms)
import { DashboardBlock } from '../DashboardBlock';
import {
  DuckyIcon,
  PersonIcon,
  ArrowDownIcon,
  LinkIcon,
  Link2Icon,
} from 'components/icons';
import { toast } from 'react-toastify';
import { DashboardHead } from '../DashboardHead';
import { AppLoader } from 'components/atoms';
import { Button, Input, Select, Form } from 'antd';
import { Area } from '@ant-design/plots';

// styles
import './index.scss';

// data
import { generalChartConfig, generalTooltipConfig } from 'config/charts';
import { selectData, dataRangeValue } from './data';

const { Option } = Select;
// func
const DashboardReferral = () => {
  const { t } = useTranslation();
  const [enabledEditReferral, setEnabledEditReferral] =
    useState<boolean>(false);
  const [currentRange, setCurrentRange] = useState<any>(
    dataRangeValue[0].value,
  );
  const [currentSelect, setCurrentSelect] = useState<string>(
    selectData[0].value,
  );

  const createReferralSchema = yup.object().shape({
    code: yup
      .string()
      .required(t('validationRequired'))
      .matches(/^[a-zA-Z0-9\-_]+$/u, t('validationQueryString')),
  });

  const yupSync = {
    async validator({ field }, value) {
      await createReferralSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const { data: referralChart, isLoading: isLoadingReferralChart } =
    useQueryFetchReferralChart(currentRange);

  const referralChartData = useMemo(() => {
    return referralChart && !isLoadingReferralChart ? referralChart : null;
  }, [referralChart, isLoadingReferralChart]);

  const totalEarned = useMemo(() => {
    return referralChart
      ? referralChart.reduce(
          // @ts-ignore
          (acc, currentValue) => acc + currentValue.scales,
          0,
        )
      : 0;
  }, [referralChart]);

  const { xAxis, yAxis, ...chartConfig } = generalChartConfig;

  const config = {
    data: referralChartData,
    xField: 'Date',
    yField: 'scales',
    ...chartConfig,
    xAxis: { ...xAxis, tickCount: 7 },
    yAxis: { ...yAxis, tickCount: 3, position: 'left' },
    tooltip: {
      ...generalTooltipConfig,
      customContent: (title, items) => {
        if (items && items.length) {
          const { data } = items[0];
          return (
            <div className="g2-tooltip__content">
              <div className="g2-tooltip__date">
                {dayjs(title).format('MMMM D, HH:mm')}
              </div>
              <div className="g2-tooltip__price">{data.scales}</div>
              <div className="g2-tooltip__count">
                {t('sold')} <span>{data.count}</span>
              </div>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  };

  const [form] = Form.useForm();

  const {
    data: referralLinkData,
    isLoading: referralLinkLoading,
    refetch: refetchReferralLinkData,
  } = useQueryFetchReferralLink();

  const {
    data: setReferralData,
    isLoading: isSetReferralLoading,
    mutate: mutateSetReferral,
  } = useMutationSetReferral();

  useEffect(() => {
    if (referralLinkData && !referralLinkLoading) {
      form.setFieldsValue({ code: referralLinkData?.link });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralLinkLoading, referralLinkData]);

  useEffect(() => {
    if (setReferralData && !isSetReferralLoading) {
      setEnabledEditReferral(false);
      toast.success(t('toastUpdatedReferralLink'));
      refetchReferralLinkData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setReferralData, isSetReferralLoading]);

  const onChangeSelect = (value: string) => {
    setCurrentRange(dataRangeValue.find(el => el.id === value)?.value);
  };

  const onSaveReferralLink = () => {
    form.submit();
  };

  const onSetReferral = payload => {
    mutateSetReferral(payload);
  };

  const onCopyReferral = () => {
    let textarea = document.createElement('textarea');
    textarea.value =
      window.location.origin + '?referral=' + referralLinkData?.link;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  return (
    <div className="dashboard__main">
      <div className="dashboard__main--wrap">
        <DashboardHead title={t('referalSystem')}>
          <></>
        </DashboardHead>
        {referralLinkLoading ? (
          <div className="dashboard__main-loader">
            <AppLoader type={ELoaderTypeType.Component} />
          </div>
        ) : (
          <>
            <DashboardBlock
              gradient
              title={t('yourIncome')}
              description={t('yourIncomeDesc')}
            >
              <div className="dashboard__referral">
                <div className="dashboard__referral--left">
                  <div className="dashboard__referral--card">
                    <div className="dashboard__referral--card-head">
                      <div className="dashboard__referral--card-title">
                        {t('totalBuyings')}
                      </div>
                    </div>
                    <div className="dashboard__referral--card-body">
                      <div className="dashboard__referral--card-value">
                        <h3>
                          ${' '}
                          {
                            formatPartsPrice(referralLinkData?.income)
                              .significantDigit
                          }
                          <span>
                            .
                            {
                              formatPartsPrice(referralLinkData?.income)
                                .fractionDigit
                            }
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard__referral--card">
                    <div className="dashboard__referral--card-head">
                      <div className="dashboard__referral--card-title">
                        {t('attracted')}
                      </div>
                    </div>
                    <div className="dashboard__referral--card-body">
                      <div className="dashboard__referral--card-value">
                        <h3>
                          <PersonIcon />
                          {referralLinkData?.referrals}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard__referral--right">
                  <div className="dashboard__referral--card dashboard__referral--card-glow">
                    <div className="dashboard__referral--card-head">
                      <div className="dashboard__referral--card-title">
                        {t('totalEarnings')}
                      </div>
                      <div className="dashboard__referral--card-action">
                        <Select
                          className="ant-select--rounded"
                          size={ESelectSizeType.Small}
                          suffixIcon={<ArrowDownIcon />}
                          defaultValue={currentSelect}
                          onChange={onChangeSelect}
                          dropdownAlign={{ offset: [0, 0] }}
                        >
                          {selectData?.length &&
                            selectData.map((el, index) => {
                              return (
                                <Option
                                  label={t(el.label)}
                                  value={el.value}
                                  key={index}
                                >
                                  {t(el.label)}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                    <div className="dashboard__referral--card-body">
                      <div className="dashboard__referral--card-value">
                        <h3>
                          $ {formatPartsPrice(totalEarned).significantDigit}
                          <span>
                            .{formatPartsPrice(totalEarned).fractionDigit}
                          </span>
                        </h3>
                      </div>
                      <div className="dashboard__referral--card-income">
                        {/*+9.55 (2%) {t('inWeek')}*/}
                      </div>
                      <div className="dashboard__referral--card-chart">
                        {/*@ts-ignore*/}
                        <Area {...config} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DashboardBlock>
            <DashboardBlock
              title={t('yourRefferal')}
              description={t('yourRefferalDesc')}
            >
              <Form onFinish={onSetReferral} form={form} layout="vertical">
                <div className="dashboard__main-form">
                  <div className="dashboard__main-form-input dashboard__main-form-input--referral">
                    <Form.Item
                      name={'code'}
                      /*@ts-ignore*/
                      rules={[yupSync]}
                    >
                      <Input
                        readOnly={!enabledEditReferral}
                        className="ant-input-group-wrapper--custom-addon"
                        addonBefore={
                          <>
                            <DuckyIcon />
                            <span>{t('referalLink')}</span>
                          </>
                        }
                        addonAfter={
                          <>
                            {!enabledEditReferral ? (
                              <Button
                                size={EButtonSizeType.Middle}
                                className="ant-btn-text--primary"
                                type="text"
                                onClick={() => {
                                  setEnabledEditReferral(true);
                                }}
                              >
                                {t('change')}
                              </Button>
                            ) : (
                              <Button
                                size={EButtonSizeType.Middle}
                                className="ant-btn-text--primary"
                                type="text"
                                onClick={() => {
                                  onSaveReferralLink();
                                }}
                              >
                                {t('save')}
                              </Button>
                            )}

                            <Button
                              size={EButtonSizeType.Middle}
                              className="ant-btn-text--primary"
                              type="text"
                              onClick={() => {
                                onCopyReferral();
                              }}
                            >
                              {t('get')} <LinkIcon />
                            </Button>
                          </>
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </DashboardBlock>
          </>
        )}
      </div>
    </div>
  );
};
export { DashboardReferral };
