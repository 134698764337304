import React, { useEffect } from 'react';

// utils

// locales
import { useTranslation } from 'react-i18next';

// store
import { useAppStore } from 'store';

// types
import { EButtonSizeType } from 'types/units';
import { EOtpType } from 'types/models';

// components(atoms)
import { Button, Input } from 'antd';
import { ModalTeleport } from 'components/atoms';
import { DashboardBlock } from '../DashboardBlock';
import { LockIcon } from 'components/icons';
import { DashboardModalResetSeances } from '../DashboardModal/DashboardModalResetSeances';
import { DashboardModalCreatePaymentPassword } from '../DashboardModal/DashboardModalCreatePaymentPassword';
import { MobileBottomNav } from 'components/features';
import { DashboardHead } from '../DashboardHead';

// styles
import './index.scss';
import { useQueryFetchProfileInfo } from '../../../../hooks/api';
import { DashboardModalEnterPassword } from '../DashboardModal/DashboardModalEnterPassword';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

// data

// func
const DashboardSecurity = () => {
  const { t } = useTranslation();
  const { sModalOpen, sSetOtpType } = useAppStore();

  const {
    data: profileData,
    isLoading: isLoadingProfileData,
    refetch: refetchProfile,
  } = useQueryFetchProfileInfo(false);

  const [query, setQuery] = useQueryParams({
    createPassword: withDefault(StringParam, undefined),
  });
  const { createPassword } = query;

  const resetQuery = () => {
    setTimeout(() => {
      setQuery({ createPassword: undefined });
    });
  };

  useEffect(() => {
    if (createPassword && createPassword.length) {
      sModalOpen('createPaymentPassword');
      resetQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  const onAuthentification = (type: boolean) => {
    type ? sSetOtpType(EOtpType.Register) : sSetOtpType(EOtpType.Reset);
    sModalOpen('twoFactorModal');
  };
  const onResetSeances = () => {
    sModalOpen('resetSeances');
  };

  const onCreatePaymentPassword = () => {
    if (profileData?.paymentPassword) {
      sModalOpen('verifyPaymentPassword');
    } else {
      sModalOpen('createPaymentPassword');
    }
  };

  const onSubmitPassword = () => {
    sModalOpen('createPaymentPassword');
  };
  const onCreatePass = () => {
    refetchProfile();
  };

  return (
    <div className="dashboard__main">
      <div className="dashboard__main--wrap">
        <DashboardHead title={t('securityAndPayments')}>
          <></>
        </DashboardHead>
        <DashboardBlock
          gradient
          title={t('payments')}
          description={t('paymentsDesc')}
        >
          .
        </DashboardBlock>
        <DashboardBlock
          title={t('paymentPassTitle')}
          description={t('paymentPassDesc')}
        >
          <div className="dashboard__main-form">
            <div className="dashboard__main-form-input">
              <Input
                className="ant-input-group-wrapper--custom-addon"
                disabled={true}
                addonBefore={
                  <>
                    <LockIcon />
                    <span>{t('paymentPassTitle')}</span>
                  </>
                }
                placeholder={
                  profileData?.paymentPassword
                    ? '************'
                    : t('paymentPassEmpty')
                }
                addonAfter={
                  <Button
                    size={EButtonSizeType.Middle}
                    className="ant-btn-text--primary"
                    type="text"
                    onClick={() => {
                      onCreatePaymentPassword();
                    }}
                  >
                    {profileData?.paymentPassword
                      ? t('updatePaymentPass')
                      : t('createPassword')}
                  </Button>
                }
              />
            </div>
          </div>
        </DashboardBlock>
        {profileData && !isLoadingProfileData && (
          <DashboardBlock
            title={t('twoFactorTitle')}
            description={t('twoFactorDesc')}
          >
            <div className="dashboard__main--align-right">
              <>
                {profileData?.twoFAEnabled ? (
                  <Button
                    type="primary"
                    size={EButtonSizeType.Middle}
                    ghost
                    className="ant-btn-ghost--error"
                    onClick={() => {
                      onAuthentification(false);
                    }}
                  >
                    {t('disableTwoFactor')}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    size={EButtonSizeType.Middle}
                    ghost
                    onClick={() => {
                      onAuthentification(true);
                    }}
                  >
                    {t('enableTwoFactor')}
                  </Button>
                )}
              </>
            </div>
          </DashboardBlock>
        )}
        <DashboardBlock
          title={t('resetSeancesTitle')}
          description={t('resetSeancesDesc')}
        >
          <div className="dashboard__main--align-right">
            <Button
              type="primary"
              className="ant-btn-ghost--warning"
              ghost
              size={EButtonSizeType.Middle}
              onClick={() => {
                onResetSeances();
              }}
            >
              {t('resetAllSeances')}
            </Button>
          </div>
        </DashboardBlock>
        <ModalTeleport
          modalId={'resetSeances'}
          width={395}
          centered
          modalClass={''}
        >
          <DashboardModalResetSeances modalId={'resetSeances'} />
        </ModalTeleport>
        <ModalTeleport
          centered
          modalId={'createPaymentPassword'}
          width={500}
          modalClass={''}
        >
          <DashboardModalCreatePaymentPassword
            modalId={'createPaymentPassword'}
            onCreate={onCreatePass}
          />
        </ModalTeleport>
        <ModalTeleport
          centered
          modalId={'verifyPaymentPassword'}
          width={500}
          modalClass={''}
        >
          <DashboardModalEnterPassword
            modalId={'verifyPaymentPassword'}
            onSubmitPassword={onSubmitPassword}
          />
        </ModalTeleport>
      </div>
    </div>
  );
};
export { DashboardSecurity };
