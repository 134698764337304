import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// utils

// locales
import { useTranslation } from 'react-i18next';
// store
import { useAppStore } from 'store';
//hooks
import {
  useQueryFetchProfileInfo,
  useQueryFetchSiteApiKey,
  useQueryShowSiteApiKey,
} from '../../../../hooks/api';

// types
import { EButtonSizeType } from 'types/units';
import { ELoaderTypeType } from '../../../../components/atoms/AppLoader/types';

// components(atoms)
import { Button, Input, Form } from 'antd';
import { DashboardBlock } from '../DashboardBlock';
import { EyeIcon, KeyBorderedIcon } from 'components/icons';
import { DashboardHead } from '../DashboardHead';
import { AppLoader, ModalTeleport } from 'components/atoms';
import { DashboardModalEnterPassword } from '../DashboardModal/DashboardModalEnterPassword';
import { toast } from 'react-toastify';
// styles
import './index.scss';

// data
import { yupSync } from './schema';
// func
const DashboardSettings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [enabledFetchKey, setEnabledFetchKey] = useState<boolean>(false);
  const [visibleApiKey, setVisibleApiKey] = useState<boolean>(false);
  const navigate = useNavigate();
  const { sModalOpen } = useAppStore();

  const { data: profileData } = useQueryFetchProfileInfo(false);

  const {
    data: siteGetApiKey,
    isLoading: isLoadingSiteGetApiKey,
    isFetching: isFetchingSiteGetApiKey,
    refetch: refetchGetApiKey,
  } = useQueryFetchSiteApiKey(enabledFetchKey);

  useEffect(() => {
    if (siteGetApiKey && !isLoadingSiteGetApiKey) {
      toast.success(t('toastApiKey'));
      refetchShowApiKey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteGetApiKey, isLoadingSiteGetApiKey]);

  const {
    data: siteShowApiKey,
    isLoading: isLoadingSiteShowApiKey,
    refetch: refetchShowApiKey,
  } = useQueryShowSiteApiKey();

  useEffect(() => {
    if (siteShowApiKey) {
      form.setFieldsValue({ siteApi: siteShowApiKey });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteShowApiKey]);
  const onSubmitForm = () => {
    setEnabledFetchKey(true);
    refetchGetApiKey();
  };

  const onSubmitPassword = () => {
    setVisibleApiKey(true);
  };

  const onGetApiKey = () => {
    if (profileData?.paymentPassword) {
      sModalOpen('verifyPaymentPasswordForApi');
    } else {
      toast.error(t('toastErrorCreatePaymentPass'));
      navigate('/dashboard/security-and-payments?createPassword=true');
    }
  };
  const onKeyVision = () => {
    if (visibleApiKey) {
      setVisibleApiKey(!visibleApiKey);
    } else {
      if (profileData?.paymentPassword) {
        sModalOpen('verifyPaymentPassword');
      } else {
        toast.error(t('toastErrorCreatePaymentPass'));
        navigate('/dashboard/security-and-payments?createPassword=true');
      }
    }
  };

  return (
    <div className="dashboard__main">
      <div className="dashboard__main--wrap">
        {isLoadingSiteShowApiKey ? (
          <div className="dashboard__main-loader">
            <AppLoader type={ELoaderTypeType.Component} />
          </div>
        ) : (
          <>
            <DashboardHead title={t('siteSettings')}>
              <></>
            </DashboardHead>
            <DashboardBlock
              title={t('apiOfSiteTitle')}
              description={t('apiOfSiteDesc')}
            >
              <div className="dashboard__main-form">
                <div className="dashboard__main-form-input dashboard__main-form-input--profile">
                  <Form form={form} layout="vertical">
                    <Form.Item
                      name={'siteApi'}
                      /*@ts-ignore*/
                      rules={[yupSync]}
                    >
                      <Input
                        disabled={true}
                        type={visibleApiKey ? 'text' : 'password'}
                        className="ant-input-group-wrapper--custom-addon"
                        addonBefore={
                          <>
                            <KeyBorderedIcon />
                            <span>Api-key</span>
                          </>
                        }
                        addonAfter={
                          <>
                            <Button
                              size={EButtonSizeType.Middle}
                              className="ant-btn-text--primary"
                              type="text"
                              onClick={() => {
                                onKeyVision();
                              }}
                              icon={<EyeIcon />}
                            ></Button>
                            <Button
                              size={EButtonSizeType.Middle}
                              className="ant-btn-text--primary"
                              type="text"
                              disabled={isFetchingSiteGetApiKey}
                              onClick={() => {
                                onGetApiKey();
                              }}
                            >
                              {t('get')}
                            </Button>
                          </>
                        }
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </DashboardBlock>
            <ModalTeleport
              centered
              modalId={'verifyPaymentPassword'}
              width={500}
              modalClass={''}
            >
              <DashboardModalEnterPassword
                modalId={'verifyPaymentPassword'}
                onSubmitPassword={onSubmitPassword}
              />
            </ModalTeleport>
            <ModalTeleport
              centered
              modalId={'verifyPaymentPasswordForApi'}
              width={500}
              modalClass={''}
            >
              <DashboardModalEnterPassword
                modalId={'verifyPaymentPasswordForApi'}
                onSubmitPassword={onSubmitForm}
              />
            </ModalTeleport>
          </>
        )}
      </div>
    </div>
  );
};
export { DashboardSettings };
